import React from "react";
import { Link } from "react-router-dom";
import { Section } from "./styles";

const ServicesPage = () => {
    return (
        <Section>
            <h1> SERVIÇOS </h1>

            <p>
                Nossa empresa dispõe de profissionais capacitados, motivados e
                comprometidos a prestar serviços de forma segura e confiável com
                máxima qualidade e eficiência, compreendendo todas as etapas e
                diferentes níveis de complexidade de um projeto, desde o
                planejamento até a efetiva entrega aos seus contratantes. Além
                disso, todos nossos serviços são executados com compromisso e
                responsabilidade ao meio ambiente e à vida de todos os nossos
                colaboradores.
            </p>

            <div className="links-container">
                <Link to="/servicos/infraestrutura" className="link">
                    <div className="link-box infraestructure">
                        <h3> INFRAESTRUTURA </h3>
                    </div>
                </Link>
                <Link to="/servicos/construcaoCivil" className="link">
                    <div className="link-box civil-construction">
                        <h3> CONSTRUÇÃO CIVIL </h3>
                    </div>
                </Link>
                <Link to="/servicos/terraplenagem" className="link">
                    <div className="link-box landscaping">
                        <h3>TERRAPLENAGEM</h3>
                    </div>
                </Link>
                <Link to="/servicos/supressao" className="link">
                    <div className="link-box supression">
                        <h3> SUPRESSÃO </h3>
                    </div>
                </Link>
                <Link to="/servicos/mineracao" className="link">
                    <div className="link-box mining">
                        <h3> MINERAÇÃO </h3>
                    </div>
                </Link>
            </div>
        </Section>
    );
};

export default ServicesPage;
