import styled, { css } from "styled-components";

export const Container = styled.nav`
	width: 100%;
	height: 8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--clr-secondary);
	z-index: 999;

	a {
		cursor: pointer;
	}

	.logo {
		height: 100%;
		margin-left: 1rem;
		display: block;
		text-align: center;
		img {
			object-fit: contain;
			height: 90%;
			padding: 0.5rem 0;
		}

		p {
			font-size: 1.125rem;
			font-weight: 600;
		}
	}

	.middle {
		display: flex;
		column-gap: 3rem;
		justify-content: space-between;
		margin-right: 9rem;
		a {
			text-decoration: none;
			font-style: normal;
			color: black;
			font-size: 1.125rem;
			font-weight: 600;
			padding-right: 2rem;
		}
	}

	.item-container {
		display: flex;
		justify-content: center;
		text-align: left;
		line-height: 2.5rem;
		height: 2.5rem;
		border-right: solid 3px;
		padding-right: 1rem;
		margin-left: 1rem;
		white-space: nowrap;
	}

	.last-item-container {
		display: flex;
		justify-content: center;
		text-align: left;
		line-height: 2.5rem;
		height: 2.5rem;
		padding-right: 1rem;
		margin-left: 1rem;
	}

	.navbar-toggle {
		display: none;
		margin-right: 3rem;
		margin-top: 1rem;
		font-size: 3.5rem;
		background: none;
		color: black;
		cursor: pointer;
		&:hover {
			background-color: initial !important;
		}
	}

	@media (max-width: 1820px) {
		.middle {
			margin-right: 3rem;
		}
	}

	@media (max-width: 1620px) {
		.middle {
			margin-right: 1rem;
		}
	}

	@media (max-width: 1620px) {
		.last-item-container {
			padding-right: 0.25rem;
			margin-left: 0.25rem;
		}
		.item-container {
			padding-right: 0.25rem;
			margin-left: 0.25rem;
		}
	}

	@media (max-width: 1479px) {
		height: 6.5rem;
		.logo {
			img {
				height: 7rem;
			}
		}
		.middle {
			justify-content: initial;
			margin-right: initial;

			flex-direction: column;
			width: 250px;
			height: 100%;

			background-color: var(--clr-secondary);
			border-left: solid black 1px;
			border-bottom: solid black 1px;

			position: fixed;
			top: 0;
			right: 0;
			transform: translateX(100%);
			transition: 250ms;

			${({ isSidebarVisible }) =>
				isSidebarVisible &&
				css`
					transform: translateX(0);
				`}

			& > * {
				border: initial;
				min-height: 45px;
				align-items: center;
				padding-right: initial;
				margin-left: initial;

				&:hover {
					transition-timing-function: ease;
					transition-duration: 0.6s;
					background-color: var(--clr-primary);
				}
				a {
					display: flex;
					justify-content: center;
					align-items: center;

					width: 100%;
					height: 100%;
					padding: 0.5rem 0rem;
				}
			}
		}

		.navbar-toggle {
			display: block;
		}
	}

	@media (max-width: 900px) {
		height: 5rem;
		.logo {
			img {
				height: 6.5rem;
				padding: 1rem 0;
			}

			p {
				font-size: 1rem;
				font-weight: 600;
			}
		}
		.navbar-toggle {
			font-size: 2.5rem;
		}
	}

	@media (max-width: 420px) {
		.logo {
			margin-left: 1rem;
		}
		.navbar-toggle {
			margin-right: 1rem;
		}
	}
`;
