import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import {
    ButtonContainer,
    CustomDiv,
    CustomForm,
    CustomHeader,
    InputContainer,
    StyledButton,
    SubmitImageButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { Container as AboutUsContainer } from "../../User/AboutUsPage/styles";

const AddMemberImagePage = () => {
    let { id } = useParams();
    const [actualImage, setActualImage] = useState();
    const [image, setImage] = useState();

    useEffect(() => {
        api.get(`/members/show/${id}`)
            .then((response) => {
                setActualImage(response.data.image_url);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    const editImage = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image", image);

        api.post(`members/edit_image/${id}`, formData)
            .then((response) => {
                toast.success("imagem editada");

                api.get(`/members/show/${id}`)
                    .then((response) => {
                        setActualImage(response.data.image_url);
                    })
                    .catch((e) => {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    });
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    return (
        <Container>
            <CustomHeader>Edite a imagem do membro:</CustomHeader>

            <CustomDiv>
                {actualImage && ( // Pegando estilo da imagem diretamente da página AboutUs
                    <AboutUsContainer>
                        <div
                            className="team-section"
                            style={{ display: "contents" }}
                        >
                            <div
                                className="member-cards"
                                style={{ display: "contents" }}
                            >
                                <div
                                    className="member-card"
                                    style={{ display: "contents" }}
                                >
                                    <img
                                        src={
                                            apiPhoto.defaults.baseURL +
                                            actualImage
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </AboutUsContainer>
                )}
            </CustomDiv>

            <CustomForm onSubmit={editImage}>
                <InputContainer>
                    <label htmlFor="description">Editar imagem</label>
                    <p className="label-description">
                        Arraste uma imagem para a caixa pontilhada ou procure
                        uma em seu dispositivo
                    </p>
                    <input
                        accept="image/*"
                        placeholder="file"
                        type="file"
                        onChange={(event) => setImage(event.target.files[0])}
                    />
                </InputContainer>

                <SubmitImageButton
                    disabled={!image}
                    className="input-btn"
                    type="submit"
                >
                    Editar
                </SubmitImageButton>
            </CustomForm>

            <ButtonContainer className="button-container">
                <Link to={`/admin/membros/${id}`}>
                    <StyledButton variant="contained">Concluir</StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default AddMemberImagePage;
