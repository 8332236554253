import { ArrowBack, ArrowFront } from "./styles"


/* The direction prop accepts 2 directions: front and back */
const CarouselArrow = ({direction, ...props}) => {
  return (
    direction === 'back' ? <ArrowBack {...props} /> : <ArrowFront {...props}/>
  )
}

export default CarouselArrow