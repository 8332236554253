import React, { useEffect } from "react";
import {
    ButtonContainer,
    Container,
    StyledButton,
    Field,
} from "../../../styles/Adm/ShowPages/styles";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import { Carousel } from "../../../components/Carousel";
import toast from "react-hot-toast";

const ShowEquipment = () => {
    let { id } = useParams();

    const [equipment, setEquipment] = useState({});
    const fetchEquipment = async () => {
        await api
            .get(`/equipments/show/${id}`)

            .then((response) => {
                setEquipment(response.data);
            })

            .catch((response) => {
                toast.error("Equipamento não encontrado.");
            });
    };

    useEffect(() => {
        fetchEquipment();
    }, [id]); // eslint-disable-line

    const formatCarouselContent = ({ images_url }) => {
        return images_url.map((url) => {
            return { url: url, isImage: true };
        });
    };

    return (
        <Container>
            <Field>
                <h1> {equipment.name} </h1>
            </Field>
            <Field>
                <h2> Marca </h2>
                <p> {equipment.brand} </p>
            </Field>
            <Field>
                <h2> Ano </h2>
                <p> {equipment.year} </p>
            </Field>
            <Field>
                <h2> Modelo </h2>
                <p> {equipment.version} </p>
            </Field>
            <Field>
                <h2> Série </h2>
                <p> {equipment.series} </p>
            </Field>
            <Field>
                <h2> À venda? </h2>
                {equipment.for_sale ? <p> Sim </p> : <p> Não </p>}
            </Field>
            <Field>
                <h2> Descrição </h2>
                <p> {equipment.description} </p>
            </Field>
            <Field>
                <h2> Local </h2>
                <p> {equipment.place} </p>
            </Field>
            <Field>
                <h2> Horímetro </h2>
                <p> {equipment.hour_meter} </p>
            </Field>
            <Field>
                <h2> Preço </h2>
                {equipment.price ? (
                    <p> R$ {equipment.price / 100} </p>
                ) : (
                    <p> A Combinar </p>
                )}
            </Field>
            <Field className={equipment.images_url ? "carousel-container" : ""}>
                {equipment.images_url ? (
                    <Carousel
                        photos={formatCarouselContent(equipment)}
                        carouselFor={equipment.name}
                        color={"var(--clr-primary)"}
                    />
                ) : (
                    <p>Não há imagens</p>
                )}
            </Field>
            <ButtonContainer className="button-container">
                <Link to={"/admin/equipamentos/editar/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to={"/admin/equipamentos/editar_imagens/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to="./../">
                    <StyledButton variant="contained" fullWidth>
                        Concluir
                    </StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default ShowEquipment;
