import React from "react";
import { useLocation } from "react-router-dom";
import Mailer from "../../../components/Mailer";
import Map from "../../../components/Map";
import { Container } from "./styles";

const ContactPage = () => {
	const location = useLocation();
	const defaultContactOption = location?.state?.props?.defaultContactOption;

	return (
		<Container className="inverted-colors">
			<div className="header-type">
				<h1>FALE CONOSCO</h1>
				<p>
					Canal utilizado para reclamações, sugestões e propostas de
					fornecedores. Pode ser usado por qualquer pessoa, seja você parte da
					empresa ou não, de forma pessoal ou anônimo(a).
				</p>
			</div>
			<div className="mailer-container">
				<Mailer defaultOption={defaultContactOption} />
			</div>
			<div className="header-type">
				<h1>FAÇA-NOS UMA VISITA</h1>
				<Map textColor="white" />
			</div>
		</Container>
	);
};

export default ContactPage;
