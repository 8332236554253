import React, { useEffect } from "react";
import {
    ButtonContainer,
    Container,
    StyledButton,
    Field,
} from "../../../styles/Adm/ShowPages/styles";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import toast from "react-hot-toast";

const ShowMember = () => {
    let { id } = useParams();

    const [member, setMember] = useState({});
    const fetchMember = async () => {
        await api
            .get(`/members/show/${id}`)

            .then((response) => {
                setMember(response.data);
            })

            .catch((response) => {
                toast.error("Membro não encontrado.");
            });
    };
    useEffect(() => {
        fetchMember();
    }, [id]); // eslint-disable-line

    return (
        <Container>
            <Field>
                <img
                    src={apiPhoto.defaults.baseURL + member.image_url}
                    className="main-photo"
                    alt="Foto do membro"
                />
            </Field>
            <Field>
                <h1> {member.name} </h1>
            </Field>
            <Field>
                <h2> Contato </h2>
                <p> {member.contact_info} </p>
            </Field>
            <Field>
                <h2> Descrição </h2>
                <p> {member.description} </p>
            </Field>
            <ButtonContainer className="button-container">
                <Link to={"/admin/membros/editar/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to={"/admin/membros/editar_imagens/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar Imagem
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to="./../">
                    <StyledButton variant="contained" fullWidth>
                        Concluir
                    </StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default ShowMember;
