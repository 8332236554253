import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import {
    ButtonContainer,
    Container,
    Form,
    Header,
    Input,
    InputContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { useRef } from "react";
import toast from "react-hot-toast";

let retryUpdate = false;
let updated = false;
let startingMember = {};

const cleanup = () => {
    retryUpdate = false;
    updated = false;
    startingMember = {};
};

const EditMemberPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [member, setMember] = useState(startingMember);
    const memberRef = useRef();
    memberRef.current = { member, updated, retryUpdate };

    useEffect(() => {
        async function fetchMember() {
            await api
                .get(`/members/show/${id}`)
                .then((response) => {
                    setMember(response.data);
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        toast.error("Membro não encontrado");
                    } else {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    }
                });
        }

        if (!memberRef.current.retryUpdate) {
            fetchMember();
        }
    }, [id]);

    const updateMember = (
        { name, contact_info, description },
        redirect = true
    ) => {
        cleanup();

        api.patch(`/members/update/${id}`, {
            name: name,
            contact_info: contact_info,
            description: description,
        })
            .then((response) => {
                toast.success("Membro Editado com Sucesso");
                if (redirect) {
                    navigate(`/admin/membros/${response.data.id}`);
                }
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const validateMember = ({ name, contact_info, description }) => {
        if (!name || name === "") {
            toast.error("Nome não pode estar em branco");
            return false;
        }
        if (!contact_info || contact_info === "") {
            toast.error("Contato não pode estar em branco");
            return false;
        }
        if (!description || description === "") {
            toast.error("Descrição não pode estar em branco");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateMember(member)) {
            updateMember(member);
        }
    };

    useEffect(() => {
        const handleLinkClick = (e) => {
            const target = e.target || e.srcElement;
            if (!memberRef.current.updated) {
                return;
            }

            if (target.tagName === "A" || target.parentNode.tagName === "A") {
                if (window.confirm("Salvar alterações?")) {
                    e.preventDefault();

                    const current = { ...memberRef.current };

                    if (validateMember(current.member)) {
                        updateMember(current.member, false);
                    } else {
                        navigate(`/admin/membros/editar/${id}`); // corrigir campos invalidados
                        retryUpdate = true;
                        startingMember = { ...current.member };
                    }
                } else {
                    cleanup();
                }
            }
        };
        setTimeout(() => {
            document.addEventListener("click", handleLinkClick);
        }, 100);

        return () => {
            setTimeout(() => {
                document.removeEventListener("click", handleLinkClick);
            }, 100);
        };
    });

    return (
        <Container>
            <Header>
                <h1>Editar Membro</h1>
                <h2>Modifique os campos para editar este membro</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Membro</label>
                    <Input
                        placeholder="Nome do membro*"
                        value={member.name}
                        id="name"
                        onChange={(e) => {
                            setMember({ ...member, name: e.target.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="contact_info">Contato</label>
                    <p className="label-description">
                        Escreva o contato do membro e, se necessário, o meio de
                        contato
                    </p>
                    <Input
                        placeholder="Contato*"
                        value={member.contact_info}
                        id="contact_info"
                        onChange={(e) => {
                            setMember({
                                ...member,
                                contact_info: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">
                        Preencha com a descrição que deve aparecer no site. Pode
                        ser uma mais extensa ou apenas o papel que desempenha na
                        empresa.
                    </p>
                    <Input
                        placeholder="Descrição*"
                        value={member.description}
                        multiline
                        minRows={4}
                        id="description"
                        onChange={(e) => {
                            setMember({
                                ...member,
                                description: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <Link to={`/admin/membros/editar_imagens/${id}`}>
                    <StyledButton
                        variant="contained"
                        endIcon={<MdEdit size={14} />}
                    >
                        Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <StyledButton
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    fullWidth
                >
                    Concluir
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default EditMemberPage;