import styled from "styled-components";

export const PageWrapper = styled.section`
	height: max-content;
	min-height: 70vh;

	display: flex;
	flex-direction: column;
`;

export const HeaderArea = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
`;
