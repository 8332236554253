import React from "react";
import { Container } from "./styles";
import { api, apiPhoto } from "../../../services/api";
import { useState, useEffect } from "react";
import certificate from "../../../assets/images/certificates/certificado-valido-ate-2025.webp";
import certificatePdf from "../../../assets/pdfs/certificates/certificado-valido-ate-2025.pdf";
import toast from "react-hot-toast";

const AboutUsPage = () => {
    const [members, setMembers] = useState([]);

    const certificates = [{ img: certificate, pdf: certificatePdf }];

    useEffect(() => {
        api.get("members/")
            .then((response) => {
                setMembers(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, []);

    return (
        <Container>
            <section className="intro-section">
                <h1 className="title">MARK`S ENGENHARIA</h1>
                <p>
                    A Mark’s Engenharia iniciou suas atividades em março de
                    1994, uma das premissas de seu plano de negócios tinha como
                    propósito atuar em um ramo de atividade extremamente
                    relevante para o desenvolvimento e o bem-estar da sociedade,
                    prestando serviços com qualidade, segurança, pontualidade e
                    transparência. <br />
                    Nestes mais de 25 anos de história, com várias obras
                    entregues e todo o conhecimento adquirido, aliado à
                    constante incorporação das novas tecnologias construtivas,
                    capacitou-se em diversos segmentos da Engenharia Civil, como
                    Infraestrutura, Construção Civil, Indústria e Mineração.{" "}
                    <br /> A Mark’s Engenharia se orgulha de conduzir suas
                    atividades seguindo princípios de honestidade e ética, somos
                    convictos que a credibilidade, o respeito e a confiança do
                    mercado e da sociedade em geral que conquistamos ao longo de
                    todos esses anos, deve ser mantida e assegurada dia após
                    dia, para tanto nos comprometemos permanentemente em
                    conduzir a tomada de nossas decisões e ações pautadas nas
                    melhores práticas de administração e defenderemos sempre
                    princípios fundamentais de respeito e proteção ao bem-estar
                    de todos os nossos Colaboradores, Fornecedores, Clientes,
                    Parceiros e Sociedade em geral.
                </p>

                <h2 className="subtitle">Missão</h2>
                <p>
                    Reconhecendo os perigos e riscos associados à nossa
                    atividade no ramo da Engenharia Civil, executar serviços com
                    qualidade, responsabilidade social e ambiental, gerando
                    resultados dentro da ética e legalidade, para atender as
                    expectativas dos Clientes, Colaboradores, Fornecedores,
                    Parceiros e Sociedade. <br />
                    <small>(DSQ.02 – Rev.03 – Aprovada em 15/12/2021)</small>
                </p>

                <h2 className="subtitle">Visão</h2>
                <p>
                    Ser nacionalmente reconhecida como uma das principiais
                    empresas prestadoras de serviços de Engenharia Civil,
                    referência por compreender continuamente os aspectos
                    tecnológicos e humanos de um mercado de trabalho inovador e
                    globalizado e por contribuir para o desenvolvimento social e
                    econômico do país.
                    <br />
                    <small>(DSQ.03 – Rev.03 – Aprovada em 15/12/2021)</small>
                </p>

                <h2 className="subtitle">Valores</h2>
                <p>
                    <ul>
                        <li>
                            <b>Ética:</b>
                            <br />
                            Princípio que rege nossa conduta profissional e
                            nosso compromisso em defender uma postura
                            responsável, transparente e de respeito mútuo entre
                            colaboradores, clientes, parceiros e fornecedores.
                        </li>
                        <li>
                            <b>Transparência:</b>
                            <br /> Compromisso com a verdade, honestidade e
                            credibilidade em todos os nossos atos.
                        </li>
                        <li>
                            <b>Comprometimento:</b>
                            <br /> Ser referência de excelência em produtos e
                            serviços prestados.
                        </li>
                        <li>
                            <b>Valorização do Capital Humano:</b>
                            <br /> Busca permanente da capacitação e
                            desenvolvimento profissional dos colaboradores.
                        </li>
                        <li>
                            <b>Satisfação do cliente:</b>
                            <br /> Prever e antecipar as necessidades dos
                            clientes para surpreendê-los positivamente.
                        </li>
                        <li>
                            <b>Saúde, Segurança e Meio Ambiente:</b>
                            <br /> Conjunto de diretrizes fundamentais para
                            condução das nossas atividades.
                        </li>
                        <li>
                            <b>Responsabilidade Social:</b> <br /> Acreditamos
                            ser a única forma de crescer em uma sociedade mais
                            justa.
                        </li>
                        <li>
                            <b>Sustentabilidade:</b> <br /> A produção e o lucro
                            compatível são alcançados através de um equilíbrio
                            conjunto.
                        </li>
                        <li>
                            <b> Empreendedorismo:</b> <br /> Ter visão para
                            novos desafios.
                        </li>
                    </ul>
                    <small>(DSQ.04 – Rev.03 – Aprovada em 15/12/2021)</small>
                </p>
            </section>

            <section className="team-section inverted-colors">
                <h1 className="title">NOSSA EQUIPE</h1>
                <p className="team-description">
                    A Mark’s dispõe de corpo técnico e operacional com mais de
                    820 colaboradores capacitados, motivados e comprometidos a
                    prestar todos os serviços, desde a fase de implantação até a
                    fase de operação.
                </p>

                <div className="member-cards">
                    {members.map((member) => {
                        return (
                            <div className="member-card" key={member.id}>
                                <img
                                    src={
                                        apiPhoto.defaults.baseURL +
                                        member.image_url
                                    }
                                    alt={`Imagem de ${member.name}`}
                                />

                                <div className="member-info">
                                    <p>{member.name}</p>
                                    <p>{member.description}</p>
                                    <p>{member.contact_info}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>

            <section className="certificates-section">
                <h1 className="title">CERTIFICADOS</h1>

                <div className="certificates">
                    {certificates.map(({ img, pdf }, i) => {
                        return (
                            <a
                                key={i}
                                href={pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={img} alt="" />
                            </a>
                        );
                    })}
                </div>
            </section>
        </Container>
    );
};

export default AboutUsPage;
