import styled from "styled-components";

export const Container = styled.div`
	background-color: var(--clr-primary);
	width: 80%;
	margin-top: 3rem;
	display: ${({ style }) => style?.display || "flex"};
	flex-direction: row;
	justify-content: space-between;

	.project-info {
		width: 70%;
		padding-left: var(--index-info-padding-left);
		flex: 1;
		.links-list {
			margin-left: 2rem;
			color: #fff;
		}
	}

	h4 {
		margin-top: 4rem;
		margin-bottom: 2rem;
		font-family: var(--font-family-tertiary);
		font-size: calc(var(--font-size-text) + 0.2rem);
		font-weight: 700;
		color: #ffffff;
		cursor: pointer;
	}

	p,
	a {
		margin-top: 1rem;
		padding-block: initial !important;
		font-family: var(--font-family-tertiary);
		margin-bottom: 0.8em;
		color: #ffffff;
	}

	a {
		cursor: pointer;
		display: block;
		text-decoration: none;
	}

	.project-logo img {
		padding: 3rem;
		width: var(--index-img-size);
		height: var(--index-img-size);
	}

	@media only screen and (max-width: 900px) {
		flex-flow: column nowrap;
		width: 100%;
		.project-logo {
			margin: auto;
			img {
				width: calc(10rem + 30vw);
				height: calc(10rem + 30vw);
			}
		}
		.project-info {
			margin: auto;
			width: 80%;
			padding-bottom: 2rem;
			h4 {
				margin-top: 1rem;
			}
		}
	}

	@media only screen and (max-width: 470px) {
		width: 100%;
		.project-info {
			a {
				font-size: 0.8rem;
			}
		}
	}
`;
