import styled from "styled-components";
import landscaping_img from "../../../assets/images/ServicesPage/Compactacao/Compactacao_de_aterro_3.jpg";
import supression_img from "../../../assets/images/ServicesPage/Supressao/marks_0re-2_1.jpg";
import mining_img from "../../../assets/images/ServicesPage/Escavacao/Execucao_de_canal_1.jpg";
import infraestructure_img from "../../../assets/images/ServicesPage/Estradas/DSC09576.jpg";
import construction_img from "../../../assets/images/ServicesPage/Concreto/Concreto_piso_com_barra_de_transferencia_1.jpg";

export const Section = styled.section`
	height: fit-content;
	display: flex;
	flex-direction: column;

	.links-container {
		width: 100%;
		padding: 0 4rem;
		margin: 4rem 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 4rem;
	}

	.link {
		margin: 0 auto;
		text-decoration: none;
	}

	.link:hover {
		cursor: pointer;
	}

	.link-box {
		height: 30rem;
		width: 21.5rem;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		margin: 1.5rem 0.5rem 1.5rem 0.5rem;
		transition: ease 0.3s;

		h3 {
			font-family: var(--font-family-secondary);
			font-weight: 700;
			font-size: 2rem;
			text-shadow: -1px 2px grey, 2px 1px grey, 1px 2px grey, 2px -1px grey;
			color: #ffffff;
			margin-bottom: 1rem;
		}

		&.infraestructure {
			background: url(${infraestructure_img}) no-repeat;
			background-size: cover;
			background-position: center;
		}

		&.civil-construction {
			background: url(${construction_img}) no-repeat;
			background-size: cover;
			background-position: center;
		}

		&.landscaping {
			background: url(${landscaping_img}) no-repeat;
			background-size: cover;
			background-position: center left;
		}

		&.supression {
			background: url(${supression_img}) no-repeat;
			background-size: cover;
		}

		&.mining {
			background: url(${mining_img}) no-repeat;
			background-size: cover;
			background-position: center;
		}

		&:hover {
			transform: scale(1.05);
		}
	}

	@media screen and (max-width: 500px) {
		.link-box {
			height: 23.4rem;
			width: 16.8rem;
		}

		.link-box h3 {
			font-size: 1.44rem;
		}
	}

	@media screen and (max-width: 400px) {
		.link-box {
			height: 19.5rem;
			width: 14rem;
		}

		.link-box h3 {
			font-size: 1.2rem;
		}
	}
`;
