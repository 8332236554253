import styled from "styled-components";
import Button from "../../components/Button";
import Input from "../../components/Input";

export const Container = styled.div`
	background-color: var(--clr-primary);
	height: max-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: calc(2vh + 3rem) 0;
	text-align: center;
	margin-top: 5vh;

	.form-container {
		width: min(calc(40rem + 5vw), 90%);
		margin: 0 auto 0 auto;
	}

	.h1 {
		font-weight: 600;
	}
`;

export const InputField = styled(Input)`
	&& {
		width: 100%;
		margin-top: 1rem;
	}
`;

export const StyledButton = styled(Button)`
	&& {
		margin: 2rem auto 0 auto;
		width: min(calc(25rem + 1vw), 90%);
		padding-block: 14px;
		background-color: black;
		color: white;
		&:hover {
			background-color: rgb(40 40 40);
		}
	}
`;
