import React, { useEffect, useRef, useState } from "react";
import LinkInput from "../../../components/LinkInput";
import {
    ButtonContainer,
    Container,
    Form,
    Header,
    Input,
    InputContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Creatable from "../../../components/Creatable";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import toast from "react-hot-toast";
import { getOptionsFromArrOfObjs } from "../../../services/utils/getOptions";

let retryUpdate = false; // true se tentou sair da página e salvar valores invalidos
let updated = false; // true se alterou localmente algum atributo da model
let startingProject = {};
let startingLinks = {};
let startingLinkId = 0;

const cleanup = () => {
    retryUpdate = false;
    updated = false;
    startingProject = {};
    startingLinks = {};
    startingLinkId = 0;
};

const EditProjectPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [project, setProject] = useState(startingProject);
    const [links, setLinks] = useState(startingLinks);
    const [linkId, setLinkId] = useState(startingLinkId);

    const projectRef = useRef();
    projectRef.current = { project, links, linkId, updated, retryUpdate };

    const [clientOptions, setClientOptions] = useState([]);
    const [placeOptions, setPlaceOptions] = useState([]);

    const orderValueFunction = (a, b) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        // a must be equal to b
        return 0;
    };

    useEffect(() => {
        async function fetchProject() {
            await api
                .get(`/projects/show/${id}`)
                .then((response) => {
                    response.data.start_date = response.data.start_date
                        ?.slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/");
                    response.data.end_date = response.data.end_date
                        ?.slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/");
                    setProject(response.data);
                    setLinkId(response.data.youtube_links.length);
                    response.data.youtube_links.forEach((code, index) => {
                        setLinks((links) => {
                            return {
                                ...links,
                                ["link" + index]:
                                    "https://www.youtube.com/watch?v=" + code,
                            };
                        });
                    });
                })
                .catch((e) => {
                    if (e?.response?.status === 404) {
                        toast.error("Projeto não encontrado");
                    } else {
                        const data = e?.response?.data;
                        toast.error(
                            data?.error
                                ? data?.error
                                : data?.message
                                ? data?.message
                                : e
                        );
                    }
                });
        }

        if (!projectRef.current.retryUpdate) {
            fetchProject();
        }

        async function fetchProjectsClientAndPlace() {
            await api
                .get(`/projects/`)
                .then(({ data }) => {
                    let foundClientOptions = getOptionsFromArrOfObjs(
                        data,
                        "client_name"
                    );
                    let foundPlaceOptions = getOptionsFromArrOfObjs(
                        data,
                        "place"
                    );

                    setClientOptions(
                        foundClientOptions.sort(orderValueFunction)
                    );
                    setPlaceOptions(foundPlaceOptions.sort(orderValueFunction));
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
        fetchProjectsClientAndPlace();
    }, [id]);

    const linkCode = (link) => {
        if (link.match(/^https:\/\/www\.youtube\.com\/watch\?v=/)) {
            return link.slice(32);
        } else if (link.match(/^https:\/\/youtu\.be\//)) {
            return link.slice(17);
        }
        return false;
    };

    const validDate = (date) => {
        if (date == null) {
            return {
                status: "ok",
                value: undefined,
            };
        }

        if (date?.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
            const [year, month, day] = date.split("/").reverse();
            if (day > 31 || day < 1) {
                return {
                    status: "day",
                };
            } else if (month > 12 || month < 1) {
                return {
                    status: "month",
                };
            }

            return {
                status: "ok",
                value: new Date(year, month, day),
            };
        }
        return {
            status: "invalid",
        };
    };

    const formatYtbLinks = (links) => {
        const youtube_links = [];
        let linksOk = true;
        Object.keys(links)
            .filter((link) => links[link] !== "norender" && links[link] !== "")
            .forEach((link) => {
                const code = linkCode(links[link]);
                if (code) {
                    youtube_links.push(code);
                } else {
                    linksOk = false;
                }
            });
        if (linksOk) {
            return youtube_links;
        }
        return false;
    };

    const validateProject = (
        { name, description, start_date, end_date, place, client_name },
        youtube_links
    ) => {
        const validateStartDate = validDate(start_date);
        const validateEndDate = validDate(end_date);

        if (!name || name === "") {
            toast.error("Coloque um nome");
            return false;
        }
        if (!description || description === "") {
            toast.error("Coloque uma descrição");
            return false;
        }
        if (youtube_links === false) {
            toast.error(
                "Coloque um link válido do youtube em todos os campos de link"
            );
            return false;
        }
        if (validateStartDate.status !== "ok") {
            switch (validateStartDate.status) {
                case "invalid":
                    toast.error(
                        "Coloque a data de início no formato dd/mm/aaaa"
                    );
                    break;
                case "day":
                    toast.error(
                        "Coloque um dia válido(1-31) na data de início"
                    );
                    break;
                case "month":
                    toast.error(
                        "Coloque um mes válido(1-12) na data de início"
                    );
                    break;
                default:
                    toast.error(validateStartDate.status);
                    break;
            }
            return false;
        }
        if (validateEndDate.status !== "ok") {
            switch (validateEndDate.status) {
                case "invalid":
                    toast.error(
                        "Coloque a data de término no formato dd/mm/aaaa"
                    );
                    break;
                case "day":
                    toast.error(
                        "Coloque um dia válido(1-31) na data de término"
                    );
                    break;
                case "month":
                    toast.error(
                        "Coloque um mes válido(1-12) na data de término"
                    );
                    break;
                default:
                    toast.error(validateEndDate.status);
                    break;
            }
            return false;
        }
        if (
            validateStartDate.value !== validateEndDate.value &&
            validateStartDate.value > validateEndDate.value
        ) {
            toast.error("Coloque uma data de término depois da data de início");
            return false;
        }
        if (!place || place === "") {
            toast.error("Coloque um local");
            return false;
        }
        if (!client_name || client_name === "") {
            toast.error("Coloque o nome do cliente do projeto");
            return false;
        }
        return true;
    };

    const updateProject = (
        { name, description, start_date, end_date, place, client_name },
        youtube_links,
        redirect = true
    ) => {
        cleanup();

        api.patch(`/projects/update/${id}`, {
            name,
            description,
            start_date,
            end_date,
            place,
            client_name,
            youtube_links,
        })
            .then((response) => {
                toast.success("Projeto Editado com Sucesso");
                if (redirect) {
                    navigate(`/admin/projetos/${response.data.id}`);
                }
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const youtube_links = formatYtbLinks(links);
        if (validateProject(project, youtube_links)) {
            updateProject(project, youtube_links);
        }
    };

    useEffect(() => {
        const handleLinkClick = (e) => {
            const target = e.target || e.srcElement;

            if (!projectRef.current.updated) {
                return;
            }

            if (target.tagName === "A" || target.parentNode.tagName === "A") {
                if (window.confirm("Salvar alterações?")) {
                    e.preventDefault();

                    const current = { ...projectRef.current };

                    const youtube_links = formatYtbLinks(current.links);
                    if (validateProject(current.project, youtube_links)) {
                        updateProject(current.project, youtube_links, false);
                    } else {
                        navigate(`/admin/projetos/editar/${id}`);
                        retryUpdate = true;
                        startingProject = { ...current.project };
                        startingLinks = { ...current.links };
                        startingLinkId = current.linkId;
                    }
                } else {
                    cleanup();
                }
            }
        };

        setTimeout(() => {
            document.addEventListener("click", handleLinkClick);
        }, 100);

        return () => {
            setTimeout(() => {
                document.removeEventListener("click", handleLinkClick);
            }, 100);
        };
    });

    return (
        <Container>
            <Header>
                <h1>Editar Projeto</h1>
                <h2>Modifique os campos para editar este projeto</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Projeto</label>
                    <Input
                        placeholder="Nome do projeto*"
                        id="name"
                        value={project.name}
                        onChange={(e) => {
                            setProject({ ...project, name: e.target.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">Descrição do projeto</p>
                    <Input
                        placeholder="Descrição*"
                        value={project.description}
                        multiline
                        minRows={4}
                        id="description"
                        onChange={(e) => {
                            setProject({
                                ...project,
                                description: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label>Vídeos do Youtube</label>
                    <p className="label-description">
                        Adicione, edite ou delete links
                    </p>
                    <Input
                        placeholder="Link"
                        value={links.link0}
                        onChange={(e) => {
                            setLinks({ ...links, link0: e.target.value });
                            updated = true;
                        }}
                    />
                    {Object.keys(links)
                        .filter((link) => {
                            return (
                                link !== "link0" && links[link] !== "norender"
                            );
                        })
                        .map((link) => {
                            return (
                                <LinkInput
                                    key={link}
                                    value={links[link]}
                                    onChangeFunction={(e) => {
                                        setLinks({
                                            ...links,
                                            [link]: e.target.value,
                                        });
                                        updated = true;
                                    }}
                                    onRemoveFunction={() => {
                                        setLinks({
                                            ...links,
                                            [link]: "norender",
                                        });
                                        updated = true;
                                    }}
                                />
                            );
                        })}
                    <ButtonContainer>
                        <StyledButton
                            onClick={() => {
                                setLinks({
                                    ...links,
                                    ["link" + (linkId + 1)]: "",
                                });
                                setLinkId(linkId + 1);
                                updated = true;
                            }}
                            size="medium"
                            variant="contained"
                            className="smaller-button"
                        >
                            Adicionar link
                        </StyledButton>
                    </ButtonContainer>
                </InputContainer>

                <InputContainer>
                    <label htmlFor="local">Local</label>
                    <p className="label-description">Local do projeto</p>
                    <Creatable
                        value={{
                            value: project.place,
                            label: project.place,
                        }}
                        options={placeOptions}
                        id="local"
                        onChange={(opt) => {
                            setProject({ ...project, place: opt.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="client">Cliente</label>
                    <p className="label-description">Nome do Cliente</p>
                    <Creatable
                        value={{
                            value: project.client_name,
                            label: project.client_name,
                        }}
                        options={clientOptions}
                        placeholder="Nome do Cliente*"
                        id="client"
                        onChange={(opt) => {
                            setProject({ ...project, client_name: opt.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="start-date">Data de início</label>
                    <p className="label-description">
                        Preencha o campo com a data de inicío do projeto
                    </p>
                    <Input
                        placeholder="Data de início"
                        value={project.start_date}
                        id="start-date"
                        onChange={(e) => {
                            setProject({
                                ...project,
                                start_date: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="end-date">Data de término</label>
                    <p className="label-description">
                        Preencha o campo com a data prevista para o término do
                        projeto
                    </p>
                    <Input
                        placeholder="Data de término"
                        value={project.end_date}
                        id="end-date"
                        onChange={(e) => {
                            setProject({
                                ...project,
                                end_date: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <Link to={`/admin/projetos/editar_imagens/${id}`}>
                    <StyledButton
                        variant="contained"
                        endIcon={<MdEdit size={14} />}
                    >
                        Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <StyledButton
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    Concluir
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default EditProjectPage;