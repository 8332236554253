import React, { useEffect, useState } from "react";
import { api, apiPhoto } from "../../../../services/api";
import { PageWrapper, HeaderArea } from "../styles";
import EquipmentsFilter from "./EquipmentsFilter";
import { CardWrapper, CardContentWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const EquipmentCard = ({ equipment }) => {
    const { id, name, brand, version, series, year, images_url } = equipment;

    const navigate = useNavigate();

    return (
        <CardWrapper>
            {images_url ? (
                <img
                    src={`${apiPhoto.defaults.baseURL}/${images_url[0]}`}
                    alt="Foto inválida"
                    onClick={() => navigate("/equipamentos/" + id)}
                />
            ) : (
                <img
                    src=""
                    alt="Sem fotos"
                    onClick={() => navigate("/equipamentos/" + id)}
                />
            )}
            <CardContentWrapper>
                <h4>{name}</h4>
                <br />
                <div className="grid">
                    {/* <p>{description}</p> */}

                    <p>
                        <b>Marca</b>: {brand}
                    </p>
                    <p>
                        <b>Modelo:</b> {version}
                    </p>

                    <p className="break-words">
                        <b>Série:</b> {series}
                    </p>
                    <p>
                        <b>Ano:</b> {year}
                    </p>
                </div>
            </CardContentWrapper>
        </CardWrapper>
    );
};

const EquipmentCards = ({ equipments }) => {
    return (
        <ul
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {equipments.map((equipment) => {
                return (
                    <EquipmentCard key={equipment.id} equipment={equipment} />
                );
            })}
        </ul>
    );
};

export const ForSaleEquipmentsPage = () => {
    const [equipments, setEquipments] = useState([]);
    const [filteredEquipments, setFilteredEquipments] = useState([]);

    useEffect(() => {
        api.get("/equipments")
            .then((response) => {
                setEquipments(response.data.filter((eq) => eq.for_sale));
                setFilteredEquipments(response.data);
            })
            .catch((er) => {
                toast.error(er.message);
            });
    }, []);

    return (
        <PageWrapper className="inverted-colors">
            <HeaderArea>
                <h1>EQUIPAMENTOS À VENDA</h1>

                <EquipmentsFilter
                    setFilteredEquipments={setFilteredEquipments}
                    equipments={equipments}
                />
            </HeaderArea>

            <p>
                Possuímos diversificada frota própria com cerca de 313
                equipamentos entre pesados e leves, que possibilitam a execução
                de todas as fases dos mais diversos serviços inerentes à área de
                atuação da empresa.
            </p>

            <EquipmentCards equipments={filteredEquipments} />
        </PageWrapper>
    );
};
