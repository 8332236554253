import { AdminProvider } from "./contexts/useAdminContext";
import AllRoutes from "./routes";
import NavBar from "./components/NavBar";
import { Toaster } from "react-hot-toast";

function App() {
	return (
		<AdminProvider>
			<Toaster />
			<NavBar />
			<AllRoutes />
		</AdminProvider>
	);
}

export default App;
