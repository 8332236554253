import styled from "styled-components";
import { Checkbox } from "@mui/material"

export const StyledCheckbox = styled(Checkbox)`
    && {
        //border color:
        color: var(--clr-yellow);

        // color when checked:
        &.Mui-checked {
            color: var(--clr-yellow);
        }
    }

`