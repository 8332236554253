import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/api";
import {
    Container,
    Header,
    Form,
    InputContainer,
    Input,
    ButtonContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";

const CreateProviderPage = () => {
    const [provider, setProvider] = useState({});

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, website } = provider;
        if (!name || name === "") {
            toast.error("Coloque um nome");
        } else {
            await api
                .post("/providers/create", {
                    name,
                    website,
                })
                .then((response) => {
                    toast.success("Fornecedor Criado com Sucesso");
                    navigate(`/admin/providers/${response.data.id}`);
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
    };

    return (
        <Container>
            <Header>
                <h1>Adicionar Fornecedor</h1>
                <h2>Preencha todos os campos para criar um fornecedor</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Fornecedor</label>
                    <Input
                        placeholder="Nome do fornecedor*"
                        id="name"
                        onChange={(e) =>
                            setProvider({ ...provider, name: e.target.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="website">Site</label>
                    <p className="label-description">
                        Escreva, ou cole, o link do site do fornecedor
                    </p>
                    <Input
                        placeholder="Site*"
                        id="website"
                        onChange={(e) =>
                            setProvider({
                                ...provider,
                                website: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <ButtonContainer className="button-container">
                    <StyledButton
                        onClick={handleSubmit}
                        variant="contained"
                        fullWidth
                    >
                        {" "}
                        Adicionar{" "}
                    </StyledButton>
                </ButtonContainer>
            </Form>
        </Container>
    );
};

export default CreateProviderPage;
