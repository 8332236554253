import { Tabs as MuiTabs } from "@mui/material";
import styled from "styled-components";

const Tabs = styled(MuiTabs)`
	&& {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		max-width: calc(100% - 15rem);

		.MuiTabs-scroller {
			.MuiTabs-flexContainer {
				display: flex;
				flex-wrap: wrap;
				button {
					font-size: min(3.5vw, 1rem);
					font-family: var(--font-family-tertiary);
					overflow-wrap: break-word;
					:hover {
						cursor: pointer;
						background-color: rgba(0, 0, 0, 0.03);
						transition: background-color 0.1s;
					}
				}
			}
		}
		.Mui-selected {
			color: black;
			font-weight: 500;
			border-radius: 0.5rem 0.5rem 0 0;
			background-color: var(--clr-primary);
			transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			.MuiTouchRipple-rippleVisible {
				opacity: 1;

				.MuiTouchRipple-child {
					background-color: var(--clr-primary);
				}
			}
		}

		.MuiTabs-indicator {
			background-color: var(--clr-secondary);
		}
		/* width:  50%; */
		@media (max-width: 900px) {
			max-width: initial;
		}
	}
`;

export default Tabs;
