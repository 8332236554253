import Button from "../../../components/Button";
import styled from "styled-components";

export const Container = styled.div`
	justify-content: center;
	--content-width: clamp(20rem, 60%, 30rem);
	width: var(--content-width);
	max-width: 100%;
	margin-inline: auto;
	.button-container {
		width: 100%;
	}

	h1 {
		color: var(--clr-gray);
		font-size: var(--h1-font-size);
	}

	h2 {
		font-size: var(--h2-font-size);
		font-weight: normal;
		color: #575f6e;
		margin-top: 1rem;
	}

	p {
		font-size: var(--p-font-size);
	}
`;

export const Field = styled.div`
	width: 80%;
	margin-top: 1rem;
	margin-inline: 1rem;
	height: max-content;

	.main-photo {
		float: right;
		--photo-size: clamp(7rem, calc(5rem + 5vw), 10.25rem);

		width: var(--photo-size);
		height: var(--photo-size);
		margin-right: -25%;
		@media only screen and (max-width: 450px) {
			float: initial;
		}
	}

	&.carousel-container {
		--overflow-by: calc(-60vw / 2 + var(--content-width) / 2);
		margin-left: var(--overflow-by);
		margin-right: var(--overflow-by);
		width: calc(100% var(--overflow-by));

		@media only screen and (max-width: 700px) {
			--overflow-by: calc(-80vw / 2 + var(--content-width) / 2);
		}
	}
`;

export const ButtonContainer = styled.div`
	width: 100%;

	a {
		text-decoration: none;
		display: contents;
	}
`;

export const StyledButton = styled(Button)`
	&& {
		background-color: var(--clr-yellow);
		font-size: max(0.7rem, 2%, 1.7rem);
		max-width: calc(100% - 2rem);
		margin-bottom: 1rem;
		margin-top: 2rem;
	}
`;
