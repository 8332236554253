import React from "react";
import { Section } from "../../../../styles/ServicesPages/styles.js";
import image1 from "../../../../assets/images/ServicesPage/Supressao/marks_0re-2_2.jpg";
import image2 from "../../../../assets/images/ServicesPage/Supressao/marks_0re-2_3.jpg";
import image3 from "../../../../assets/images/ServicesPage/Supressao/marks_0re-2_4.jpg";

const SupressionPage = () => {
    return (
        <Section>
            <div className="item-container">
                <h1> SUPRESSÃO </h1>
            </div>

            <div className="item-container">
                <p>
                    Há mais de 25 anos, a Mark’s Engenharia executa projetos de
                    Supressão, composta por profissionais especializados, com
                    ampla experiência na área ambiental, mantendo-se
                    constantemente atualizados de acordo com a legislação
                    ambiental. Além disso, nossos serviços contam com máquinas e
                    equipamentos de última geração, garantindo agilidade e
                    qualidade na entrega dos projetos.
                </p>
            </div>

            <div className="img-container">
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image1} alt="" />
            </div>
        </Section>
    );
};

export default SupressionPage;
