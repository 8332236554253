import { api } from "../../../services/api";
import React, { useEffect, useState } from "react";
import {
    Container,
    ElementContainer,
    Section,
    StyledButton,
} from "./../../../styles/Adm/IndexPages/styles";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const IndexMemberPage = () => {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        api.get("/members/")
            .then((response) => {
                setMembers(response.data);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, []);

    const deleteMember = (id) => {
        let confirmDelete = window.confirm(
            "Tem certeza que quer excluir esse membro?"
        );
        if (confirmDelete) {
            api.delete(`/members/delete/${id}`)
                .then(() => {
                    toast.success("Mebro deletado");
                    setMembers((members) =>
                        members.filter((mb) => mb.id !== id)
                    );
                })
                .catch((er) => {
                    switch (er?.response?.status) {
                        case 401:
                            toast.error(
                                "O usuário não pode ser autenticado. Caso realmente seja um administrador, tente sair e entrar novamente na conta."
                            );
                            break;

                        default:
                            toast.error(er.message);
                            break;
                    }
                });
        }
    };

    return (
        <>
            <Section>
                <div className="title-container">
                    <h1> Gerencie seus Membros </h1>
                </div>

                <div className="head-container">
                    <div className="table-titles">
                        <div className="containers">
                            <h2> Nome do Membro </h2>
                        </div>
                        <div className="containers null-on-mobile">
                            <h2> Contato </h2>
                        </div>
                        <div className="containers">
                            <h2> Ações </h2>
                        </div>
                    </div>
                </div>

                <Container>
                    {members.map((item, index) => (
                        <ElementContainer key={item.id}>
                            <div className="containers">
                                <Link to={`./${item.id}`}>
                                    <span> {item.name} </span>
                                </Link>
                            </div>

                            <div className="containers null-on-mobile">
                                <span> {item.contact_info} </span>
                            </div>

                            <div className="containers">
                                <Link to={`./editar/${item.id}`}>
                                    <StyledButton
                                        variant="contained"
                                        className="small-font"
                                        endIcon={<MdEdit size={14} />}
                                    >
                                        Editar
                                    </StyledButton>
                                </Link>
                                <StyledButton
                                    variant="contained"
                                    type="button"
                                    className="small-font"
                                    onClick={() => {
                                        deleteMember(item.id);
                                    }}
                                    endIcon={<AiFillDelete size={14} />}
                                >
                                    <span className="teste">Excluir</span>
                                </StyledButton>
                            </div>
                        </ElementContainer>
                    ))}
                </Container>

                <Link to="./criar">
                    <Button>Adicionar Membro</Button>
                </Link>
            </Section>
        </>
    );
};
export default IndexMemberPage;
