import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import {
    ButtonContainer,
    CustomForm,
    CustomHeader,
    InputContainer,
    RemoveImageButton,
    StyledButton,
    SubmitImageButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { Container as ProjectLogoContainer } from "../../../components/ProjectCard/styles";
import { Field } from "../../../styles/Adm/ShowPages/styles";
import { Carousel } from "../../../components/Carousel";
import toast from "react-hot-toast";

const EditProjectPhotosPage = () => {
    const formatCarouselContent = (photos_url) => {
        return photos_url.map((url) => {
            return { url: url, isImage: true };
        });
    };

    let { id } = useParams();
    const [project, setProject] = useState();
    const [photos, setPhotos] = useState([]);
    const [photo, setPhoto] = useState();
    const [logo, setLogo] = useState();
    const [updatedLogo, setUpdatedLogo] = useState();

    const [carouselShownIndex, setCarouselShownIndex] = useState(0);

    useEffect(() => {
        api.get(`/projects/show/${id}`)
            .then((response) => {
                setProject(response.data);
                setPhotos(response.data.photos_url);
                setLogo(response.data.logo_url);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    const addImage = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("photo", photo);

        api.post(`projects/add_image/${id}`, formData)
            .then((response) => {
                toast.success("imagem adicionada");

                api.get(`/projects/show/${id}`)
                    .then((response) => {
                        setProject(response.data);
                        setPhotos(response.data.photos_url);
                    })
                    .catch((e) => {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    });
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const addLogo = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("logo", updatedLogo);

        api.post(`projects/edit_logo/${id}`, formData)
            .then((response) => {
                toast.success("logo modificada");

                api.get(`/projects/show/${id}`).then((response) => {
                    setLogo(response.data.logo_url);
                });
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const deleteImg = async (e) => {
        e.preventDefault();
        api.delete(`/projects/delete_image/${id}`, {
            params: { image_url: photos[carouselShownIndex] },
        })
            .then((response) => {
                toast.success("foto deletada");
                setPhotos(
                    photos.filter((_img, i) => {
                        return i !== carouselShownIndex;
                    })
                );
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    return (
        <>
            <Container>
                <CustomHeader>Edite as imagens do Projeto:</CustomHeader>
                <Field className="carousel-container">
                    <h2> Deletar </h2>
                    {photos && photos?.length !== 0 && project?.name ? (
                        <Carousel
                            photos={formatCarouselContent(photos)}
                            carouselFor={project.name}
                            color={"var(--clr-primary)"}
                            setShownIndex={setCarouselShownIndex}
                        />
                    ) : (
                        <p>Não há imagens a serem deletadas</p>
                    )}
                </Field>

                <RemoveImageButton
                    disabled={photos == null || photos.length === 0}
                    onClick={deleteImg}
                    endIcon={<AiFillDelete size={14} />}
                >
                    Excluir
                </RemoveImageButton>

                <CustomForm onSubmit={addImage}>
                    <InputContainer>
                        <label htmlFor="description">Adicionar imagem</label>
                        <p className="label-description">
                            Arraste uma imagem para a caixa pontilhada ou
                            procure uma em seu dispositivo
                        </p>
                        <input
                            accept="image/*"
                            className="file-picker"
                            placeholder="file"
                            type="file"
                            onChange={(event) =>
                                setPhoto(event.target.files[0])
                            }
                        />
                    </InputContainer>

                    <SubmitImageButton
                        disabled={!photo}
                        className="input-btn"
                        type="submit"
                    >
                        Adicionar
                    </SubmitImageButton>
                </CustomForm>

                {logo && (
                    <ProjectLogoContainer style={{ display: "contents" }}>
                        <div
                            className="project-logo"
                            style={{ display: "contents" }}
                        >
                            <img
                                src={apiPhoto.defaults.baseURL + logo}
                                alt=""
                            />
                        </div>
                    </ProjectLogoContainer>
                )}
                <CustomForm onSubmit={addLogo}>
                    <InputContainer>
                        <label htmlFor="description">
                            Editar logo do cliente
                        </label>
                        <p className="label-description">
                            Arraste uma imagem para a caixa pontilhada ou
                            procure uma em seu dispositivo
                        </p>
                        <input
                            accept="image/*"
                            placeholder="file"
                            type="file"
                            onChange={(event) =>
                                setUpdatedLogo(event.target.files[0])
                            }
                        />
                    </InputContainer>

                    <SubmitImageButton
                        disabled={!updatedLogo}
                        className="input-btn"
                        type="submit"
                    >
                        Editar
                    </SubmitImageButton>
                </CustomForm>

                <ButtonContainer className="button-container">
                    <Link to={`/admin/projetos/${id}`}>
                        <StyledButton variant="contained">
                            Concluir
                        </StyledButton>
                    </Link>
                </ButtonContainer>
            </Container>
        </>
    );
};

export default EditProjectPhotosPage;
