import React from "react";
import { Select } from "./styles";

const CustomSelect = ({ options, placeholder, ...props }) => {
    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            closeMenuOnSelect
            placeholder={placeholder}
            options={options}
            {...props}
        />
    );
};

export default CustomSelect;
