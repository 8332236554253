import styled from "styled-components";

export const Container = styled.section`
	padding: var(--page-padding);
	background-color: var(--clr-primary);

	.info {
		padding-bottom: 5rem;
		width: 80%;
		margin: auto;
		p {
			margin-top: 2rem;
		}
	}
`;
