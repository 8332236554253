import { Tab } from "@mui/material";

import { useState } from "react";
import Tabs from "../Tabs";
import MapContainer from "./styles";

const MapTab = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <MapContainer
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </MapContainer>
    );
};

const Map = (props) => {
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <h3
                style={{
                    marginBottom: 10,
                    fontFamily: "var(--font-family-tertiary)",
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.6)",
                }}
            >
                Endereços:
            </h3>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                <Tab label="Principal" />
                <Tab label="Icoaraci" />
                <Tab label="Barcarena" />
            </Tabs>
            <MapTab {...props} value={value} index={0}>
                <iframe
                    title="Mapa do endereço principal"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.5491362464077!2d-48.471243185674155!3d-1.4452230989459915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92a48c22b17c2e7d%3A0xb81dd62c135b4d4e!2sAv.%20Jos%C3%A9%20Bonif%C3%A1cio%2C%20160%20-%2007%20-%20F%C3%A1tima%2C%20Bel%C3%A9m%20-%20PA%2C%2066090-363!5e0!3m2!1spt-BR!2sbr!4v1654177391451!5m2!1spt-BR!2sbr"
                ></iframe>
                <address>
                    Avenida José Bonifacio , 160, Sala 07 - Fátima - Belém/PA
                </address>
            </MapTab>
            <MapTab {...props} value={value} index={1}>
                <iframe
                    title="Mapa do endereço de Icoaraci"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.799115179309!2d-48.44019008524602!3d-1.2950934990554042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfc43a5271fae58d7!2zMcKwMTcnNDIuMyJTIDQ4wrAyNicxNi44Ilc!5e0!3m2!1spt-BR!2sbr!4v1659364773687!5m2!1spt-BR!2sbr"
                ></iframe>
                <address>
                    R. Samal, 3568-4140 - Maracacuera (Icoaraci), Belém - PA,
                    66815-140
                </address>
            </MapTab>
            <MapTab {...props} value={value} index={2}>
                <iframe
                    title="Mapa do endereço de Barcarena"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.358108557309!2d-48.66288408524584!3d-1.5501803988695166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb558c2d96a2e6411!2zMcKwMzMnMDAuNyJTIDQ4wrAzOSczOC41Ilc!5e0!3m2!1spt-BR!2sbr!4v1659365068012!5m2!1spt-BR!2sbr"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <address>Barcarena, PA, 68445-000</address>
            </MapTab>
        </div>
    );
};

export default Map;
