import styled from "styled-components";

export const Container = styled.section`
	.header-type {
		color: white;
		width: 100%;
	}

	.mailer-container {
		margin-block: 3rem 5rem;
		height: max-content;
	}

	.adress {
		width: min(100%, 76rem);
		margin: 2rem auto 2rem auto;
		margin-inline: auto;
		text-align: center;

		iframe {
			width: 100%;
			aspect-ratio: 2 / 1;

			border-color: var(--clr-secondary);
			border-top-color: var(--clr-primary);
			border-left-color: var(--clr-secondary);
		}
		address {
			font-family: var(--font-family-tertiary);
			font-style: normal;
			font-size: calc(var(--font-size-text) - 0.4rem);
			color: white;
			margin-top: 1rem;
			text-align: center;
		}
	}

	@media (max-width: 690px) {
		.adress {
			margin-top: 4rem;
			iframe {
				aspect-ratio: 1 / 1;
			}
		}
	}
`;
