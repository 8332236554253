import React from "react";
import { Container } from "./styles";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logosecundaria.png";
import email from "../../assets/images/e-mail1.png";
import phone from "../../assets/images/telephone1.png";

const Footer = () => {
    return (
        <Container>
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="logo da Mark's Engenharia" />
                </Link>
            </div>
            <div className="middle">
                <p>
                    {" "}
                    @ 2022 Desenvolvido por{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="http://struct.unb.br/"
                    >
                        Struct
                    </a>{" "}
                </p>
                <p> Design por Facto Agência </p>
            </div>
            <div className="right">
                <div className="right-item">
                    <img className="email" src={email} alt="mail" />
                    <div className="text">marks@marks.com.br</div>
                </div>
                <div className="right-item">
                    <img className="phone" src={phone} alt="telefone" />
                    <div className="text">(91) 3266-6909 ou (91) 3236-4020</div>
                </div>
            </div>
        </Container>
    );
};

export default Footer;
