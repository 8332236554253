import styled from "styled-components";

const UserPageLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	.page-content-wrapper {
		--page-padding: 2rem 0rem;
		--section-padding: 5rem min(6rem, 10%);
		--reverse-section-inline-padding: max(-6rem, -12.5%);
		--reverse-section-block-padding: -5rem;
		width: 100%;
		height: max-content;

		section {
			width: 100%;
			padding: var(--section-padding);

			h1,
			h2 {
				color: var(--clr-primary);
				font-family: var(--font-family-secondary);
				&::after {
					content: "";
					border-bottom: 7px currentColor solid;
					display: block;
				}
			}

			h1 {
				font-weight: bolder;
				font-size: var(--font-size-title);
				margin-bottom: 2rem;

				&::after {
					margin-top: 1rem;
					width: min(48.125rem, 60vw);
				}
			}

			h2 {
				font-weight: 500;
				margin-block: 1rem;
				font-size: calc(var(--font-size-title) - 0.5rem);

				&::after {
					border-bottom-width: 4px;
					margin-top: 0.5rem;
					width: min(16rem, 50vw);
				}
			}
			p {
				font-family: var(--font-family-tertiary);
				font-size: var(--font-size-text);
				padding-block: 1.5rem;
				text-align: justify;
				max-width: 75ch;
				line-height: 1.8em;
			}
		}
		.inverted-colors {
			background-color: var(--clr-primary);
			h1,
			h2,
			p {
				color: white;
			}
		}
	}
`;

export default UserPageLayout;
