import Radio from "../../../../../components/Radio";
import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../components/CheckBox";
import PopupArea from "../../../../../components/PopupArea";
import {
	FieldOptionsWrapper,
	FieldTitleWrapper,
	FilterWrapper,
	OptionWrapper,
} from "./styles";

const EquipmentsFilter = ({ equipments, setFilteredEquipments }) => {
	const [brandOptions, setBrandOptions] = useState([]);
	const [nameOptions, setNameOptions] = useState([]);

	const field_names = ["name", "brand"];
	const field_titles = { brand: "Marcas", name: "Tipo" };
	const options = { brand: brandOptions, name: nameOptions };

	const initial_active_filters = field_names.reduce((obj, field) => {
		return { ...obj, [field]: false };
	}, {});

	const [activeFilters, setActiveFilters] = useState(initial_active_filters);

	const initial_filters = field_names.reduce((obj, field) => {
		return { ...obj, [field]: [] };
	}, {});

	const [filterValues, setFilterValues] = useState(initial_filters);

	useEffect(() => {
		if (equipments == null) {
			return;
		}

		let tempEquipments = equipments;

		for (let [key, isActive] of Object.entries(activeFilters)) {
			if (!isActive) {
				continue;
			}

			tempEquipments = tempEquipments.filter((equipment) => {
				return filterValues[key].includes(equipment[key]);
			});
		}

		setFilteredEquipments(tempEquipments);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [equipments, activeFilters, filterValues]);

	useEffect(() => {
		setBrandOptions(
			equipments
				.map((equipment) => equipment.brand) //getting all brands
				.filter(
					(brand, index, brands) => brands.indexOf(brand) === index // filtering out repeated brands
				)
		);

		setNameOptions(
			equipments
				.map((equipment) => equipment.name)
				.filter((name, index, names) => names.indexOf(name) === index)
		);
	}, [equipments]);

	return (
		<PopupArea buttonProps={{ invertedColors: true }}>
			<FilterWrapper>
				{field_names.map((field_name) => {
					return (
						<>
							<FieldTitleWrapper>
								<Radio
									checked={activeFilters[field_name]}
									name="radio-buttons"
									onClick={() => {
										setActiveFilters((prevFilters) => {
											return {
												...prevFilters,
												[field_name]: !activeFilters[field_name],
											};
										});
									}}
								/>
								<h3>{field_titles[field_name]}</h3>
							</FieldTitleWrapper>
							<FieldOptionsWrapper
								data-visible={activeFilters[field_name]}
								aria-expanded={activeFilters[field_name]}
							>
								{options[field_name].map((opt, i) => {
									return (
										<OptionWrapper key={i} style={{ marginRight: "1rem" }}>
											<Checkbox
												onChange={(_e, checked) => {
													checked
														? setFilterValues((prevVal) => {
																return {
																	...prevVal,
																	[field_name]: [...prevVal[field_name], opt],
																};
														  })
														: setFilterValues((prevVal) => {
																return {
																	...prevVal,
																	[field_name]: prevVal[field_name].filter(
																		(el) => {
																			return el !== opt;
																		}
																	),
																};
														  });
												}}
												checked={filterValues[field_name].includes(opt)}
											/>

											<p>{opt}</p>
										</OptionWrapper>
									);
								})}
							</FieldOptionsWrapper>
						</>
					);
				})}
			</FilterWrapper>
		</PopupArea>
	);
};

export default EquipmentsFilter;
