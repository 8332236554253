import React from "react";
import {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    StyledButton,
} from "./styles";
import { HiOutlineCursorClick } from "react-icons/hi";
import Caderno from "../../../assets/images/Homepage/caderno 1.png";
import Engrenagem from "../../../assets/images/Homepage/solucao 1.png";
import Equipe from "../../../assets/images/Homepage/equipe-de-trabalho 1.png";
import Trator from "../../../assets/images/Homepage/trator 1.png";
import { Link } from "react-router-dom";
import Mailer from "../../../components/Mailer";
import Map from "../../../components/Map";

const Homepage = () => {
    return (
        <>
            <Section1 />
            <Section2 className="inverted-colors">
                <h1> MARK'S ENGENHARIA </h1>

                <p>
                    A Mark's Engenharia foi fundada em 1994, com o objetivo de
                    atender as empresas em geral nas áreas de Construção Civil,
                    Terraplenagem, Drenagem, Obras de Artes Especiais, Supressão
                    Vegetal e Mineração, mantendo sempre o compromisso de
                    oferecer aos seus clientes uma prestação de serviços com
                    máxima qualidade e eficiência. A Marks dispõe de corpo
                    técnico formado por profissionais capacitados a prestar
                    serviços técnicos e operacionais, desde a fase de
                    implantação, até a fase de operação de um empreendimento,
                    além de possuir frota própria de equipamentos leves e
                    pesados, capazes de desenvolver os mais diversos tipos de
                    serviços, ligados ao ramo de atuação da empresa.
                </p>

                <Link to="/sobreNos">
                    <StyledButton
                        variant="outlined"
                        endIcon={<HiOutlineCursorClick size={40} />}
                    >
                        Confira nossa história
                    </StyledButton>
                </Link>
            </Section2>

            <Section3>
                <h1> CONHEÇA NOSSO TRABALHO </h1>

                <div className="links-container">
                    <Link to="/projetos">
                        <div className="link-box">
                            <img className="caderno" src={Caderno} alt="" />
                            <h3> PROJETOS </h3>
                        </div>
                    </Link>
                    <Link to="/servicos">
                        <div className="link-box">
                            <img src={Engrenagem} alt="" />
                            <h3> SERVIÇOS </h3>
                        </div>
                    </Link>
                    <Link to="/equipamentos">
                        <div className="link-box">
                            <img src={Trator} alt="" />
                            <h3>EQUIPAMENTOS</h3>
                        </div>
                    </Link>
                    <Link to="/fornecedores">
                        <div className="link-box">
                            <img src={Equipe} alt="" />
                            <h3> FORNECEDORES </h3>
                        </div>
                    </Link>
                </div>
            </Section3>

            <Section4 className="inverted-colors">
                <div className="item-container">
                    <h1> CONTATO</h1>
                    <div className="white-line"></div>
                </div>
                <div className="mailer-container">
                    <Mailer />
                </div>
            </Section4>

            <Section5>
                <h1> FAÇA-NOS UMA VISITA </h1>

                <Map />
            </Section5>
        </>
    );
};

export default Homepage;
