import styled from "styled-components";

export const Container = styled.div`
	margin-top: auto;
	width: 100%;
	height: 9rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--clr-secondary);
	z-index: 2;
	p {
		text-align: center;
	}

	a {
		cursor: pointer;
	}

	.logo {
		margin: 1rem 0 1rem 9rem;
		display: block;
		text-align: center;
		img {
			height: 5rem;
		}

		p {
			font-size: 1.125rem;
			font-weight: 600;
			color: var(--clr-yellow);
		}
	}

	.middle {
		display: block;
		column-gap: 3rem;
		justify-content: space-between;

		p {
			a {
				display: contents;
			}
			text-align: center;
			margin-top: 0.5rem;
			font-size: 1.125rem;
		}
		a {
			text-decoration: none;
			font-style: normal;
			color: black;
			padding-right: 2rem;
		}
	}

	.right {
		display: block;
		margin-right: 5rem;

		.right-item {
			display: flex;
			margin: 0.5rem;
			align-items: center;
			margin-left: 0;
			padding-left: 0;

			img {
				margin-top: 0.25rem;
				margin-right: 0.5rem;
				height: 1.5rem;

				.phone {
					height: 1rem;
				}
			}

			.text {
				margin-top: 0.5rem;
				font-size: 1.125rem;
			}
		}
	}

	@media (max-width: 1240px) {
		.logo {
			margin-left: 3rem;
		}
		.right {
			margin-right: 3rem;
		}
	}

	@media (max-width: 1240px) {
		.logo {
			display: none;
		}
		.middle {
			margin-left: 3rem;
		}
	}

	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 19rem;
		height: initial;
		.middle {
			font-size: 1rem;
			margin: 0 0 0 0;
		}
		.logo {
			display: block;
			margin-top: 1rem;
			margin: 1rem 0 1rem 0;
			p {
				color: black;
			}
		}
		.right {
			margin-right: initial;
		}
		.right-item {
			justify-content: center;
			padding-inline: auto;
			p {
				max-width: 70%;
			}
		}
	}
`;
