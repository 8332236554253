import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledInput = styled(TextField)`
	--input-padding: 0.5rem;

	&& {
		width: 100%;
		padding: var(--input-padding);
		input,
		textarea {
			font-size: var(--create-input-font-size);
			line-height: initial;
		}

		label {
			padding-inline: var(--input-padding);
			&.Mui-focused {
				font-weight: 500;
				color: initial;
			}
		}

		// on focus styles:
		&:focus {
			color: var(--clr-yellow);
			input {
				color: black;
			}
		}
		div {
			&::after {
				border-bottom-color: var(--clr-yellow);
			}
		}
	}
`;
