import styled from "styled-components";
import { TextField, Button } from "@mui/material";

export const Container = styled.div`
	min-height: 50vh;
	width: 67vw;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.small-text-container {
		display: flex;
		justify-content: space-between;
		& > * {
			width: 80%;
			flex: 2;
		}
		& > :not(:first-child) {
			margin-left: 10px;
		}
	}

	.pdf-field {
		position: relative;
		height: inherit;
		min-height: 5rem;
		flex: 1;

		&::after {
			position: absolute;
			inset: 0;
			margin-block: 1rem;
			border-radius: 6px;

			background-color: white;
			border-bottom: 3px solid;
			border-right: 2px solid;
			border-color: rgba(0, 0, 0, 0.2);

			color: rgba(0, 0, 0, 0.6);
			padding-top: 0.9rem;
			text-indent: 1em;
			content: "Anexe um pdf";

			pointer-events: none;
		}
		input[type="file"] {
			width: 100%;
			height: 100%;
			display: flex;
			position: absolute;
			inset: 0;

			cursor: pointer;
		}
		input[type="file"]::file-selector-button {
			display: none;
		}
	}

	.send-button {
		position: relative;
		margin-top: -2rem;
		width: 30%;
		left: 70%;
		img {
			width: 100%;
		}

		:hover {
			cursor: pointer;
			transform: scale(0.99);
			transition: transform 0.1s;
		}
	}

	@media (max-width: 1240px) {
		.small-text-container {
			align-items: center;
			flex-direction: column;

			.small-text {
				flex: 0;
			}

			& > :not(:first-child) {
				margin-left: 0;
			}
		}
		.send-button {
			width: 32%;
			left: 68%;
		}
	}

	@media (max-width: 900px) {
		height: 85%;
		.send-button {
			width: 40%;
			left: 0;
			margin: 1rem auto 0 auto;
		}
	}
	@media (max-width: 700px) {
		.send-button {
			width: 45%;
		}
	}
	@media (max-width: 600px) {
		width: 100%;
		.send-button {
			width: 60%;
		}
	}
`;

export const SmallFieldText = styled(TextField)`
	&& {
		background-color: white;
		margin: 1rem 0rem 1rem 0rem;
		border-radius: 0.3rem;
		label {
			&.Mui-focused {
				color: #2f4f4f;
			}
		}
		.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: var(--clr-secondary);
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: transparent;
		}
	}
`;

export const LargeFieldText = styled(TextField)`
	&& {
		background-color: white;
		margin-top: 2rem;
		width: 100%;
		border-radius: 0.3rem;
		label {
			&.Mui-focused {
				color: #2f4f4f;
			}
		}
		.Mui-focused {
			.MuiOutlinedInput-notchedOutline {
				border-color: var(--clr-secondary);
			}
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: transparent;
		}
	}
`;

export const SendButton = styled(Button)`
	&& {
		right: 0;
		bottom: 0;
		width: 10vw;
	}
`;
