import React, { useEffect, useState } from "react";
import BottomNavigationForImgs from "./BottomNavigationForImgs";
import CarouselArrow from "./CarouselArrow";
import { Container } from "./styles";
import { apiPhoto } from "../../services/api";

// photos = [
//    {isImage: bool, url: string},
//      ]
export const Carousel = ({ photos, carouselFor, color, setShownIndex }) => {
	// A variable to keep track of the current image on carousel
	const [selectedIndex, setSelectedIndex] = useState(0);

	const changeImage = (_e, newSelectedIndex) => {
		setSelectedIndex(newSelectedIndex);
		setShownIndex(newSelectedIndex);
	};

	useEffect(() => {
		if (selectedIndex >= photos.length) {
			photos.length > 0 ? changeImage(0, photos.length - 1) : changeImage(0, 0);
		}
	}, [photos]); // eslint-disable-line

	return (
		<Container>
			<div className="photos">
				<CarouselArrow
					className="arrow left"
					direction="back"
					size="3.5rem"
					color={color || "#fff"}
					onClick={() => {
						const change = selectedIndex === 0 ? 0 : 1;
						changeImage(1, selectedIndex - change);
					}}
				/>
				<div className="img-container">
					{photos
						.filter((_photo, i) => i === selectedIndex)
						.map((photo) =>
							photo.isImage ? (
								<img
									key={photo.url}
									src={`${apiPhoto.defaults.baseURL}${photo.url}`}
									alt={`Uma imagem do projeto ${carouselFor}`}
								/>
							) : (
								<iframe
									key={photo.url}
									src={`https://www.youtube.com/embed/` + photo.url}
									title={"Vídeo do projeto" + carouselFor}
									allowFullScreen
								/>
							)
						)}
				</div>
				<CarouselArrow
					className="arrow right"
					direction="front"
					size="3.5rem"
					color={color || "#fff"}
					onClick={() => {
						const change = selectedIndex === photos.length - 1 ? 0 : 1;
						changeImage(undefined, selectedIndex + change);
					}}
				/>
			</div>
			<BottomNavigationForImgs
				selectedImgs={photos}
				selectedIndex={selectedIndex}
				onChange={changeImage}
				color={color || "#fff"}
			/>
		</Container>
	);
};
