import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage";

import IndexMemberPage from "../pages/Adm/IndexMembersPage";
import IndexProjectPage from "../pages/Adm/IndexProjectPage";
import IndexProviderPage from "../pages/Adm/IndexProviderPage";
import IndexEquipmentsPage from "../pages/Adm/IndexEquipmentsPage";

import AdmShowEquipment from "../pages/Adm/ShowEquipmentPage";
import AdmShowMember from "../pages/Adm/ShowMemberPage";
import AdmShowProject from "../pages/Adm/ShowProjectPage";
import AdmShowProvider from "../pages/Adm/ShowProviderPage";

import CreateMemberPage from "../pages/Adm/CreateMemberPage";
import CreateProjectPage from "../pages/Adm/CreateProjectPage";
import CreateProviderPage from "../pages/Adm/CreateProviderPage";
import CreateEquipmentPage from "../pages/Adm/CreateEquipmentPage";

import IndexProjects from "../pages/User/IndexProjects";

import ShowProject from "../pages/User/ShowProject";

import EditMemberPage from "../pages/Adm/EditMemberPage";
import EditProjectPage from "../pages/Adm/EditProjectPage";
import EditProviderPage from "../pages/Adm/EditProviderPage";
import EditEquipmentPage from "../pages/Adm/EditEquipmentPage";

import { useAdminContext } from "../contexts/useAdminContext";
import AdminPages from "../pages/Adm";
import Homepage from "../pages/User/Homepage";

import { ProvidersPage } from "../pages/User/ProvidersPage";

import UserPages from "../pages/User";
import AboutUsPage from "../pages/User/AboutUsPage";
import ContactPage from "../pages/User/ContactPage";
import ServicesPage from "../pages/User/ServicesPage";
import InfraestructurePage from "../pages/User/ServicesPage/Infraestructure";
import CivilConstructionPage from "../pages/User/ServicesPage/CivilConstruction";
import Landscaping from "../pages/User/ServicesPage/Landscaping";
import SupressionPage from "../pages/User/ServicesPage/Supression";
import MiningPage from "../pages/User/ServicesPage/Mining";
import ScrollToTop from "./utils";
import EquipmentsPage from "../pages/User/Equipments/EquipmentsPage";
import { ForSaleEquipmentsPage } from "../pages/User/Equipments/ForSaleEquipmentsPage";
import OurEquipmentsPage from "../pages/User/Equipments/OurEquipmentsPage";
import ShowEquipmentPage from "../pages/User/Equipments/Show";

import AddMemberImagePage from "../pages/Adm/EditImageMember";
import AddProviderImagePage from "../pages/Adm/EditImageProvider";
import EditEquipmentImagesPage from "../pages/Adm/EditImageEquipment";
import EditProjectPhotosPage from "../pages/Adm/EditImageProject";

const AllRoutes = () => {
    const { admin } = useAdminContext();

    return (
        <ScrollToTop>
            <Routes>
                {admin && (
                    <Route path="/admin" element={<AdminPages />}>
                        <Route path="membros" element={<IndexMemberPage />} />
                        <Route
                            path="membros/criar"
                            element={<CreateMemberPage />}
                        />
                        <Route path="membros/:id" element={<AdmShowMember />} />
                        <Route
                            path="membros/editar/:id"
                            element={<EditMemberPage />}
                        />
                        <Route
                            path="membros/editar_imagens/:id"
                            element={<AddMemberImagePage />}
                        />

                        <Route path="projetos" element={<IndexProjectPage />} />
                        <Route
                            path="projetos/criar"
                            element={<CreateProjectPage />}
                        />
                        <Route
                            path="projetos/:id"
                            element={<AdmShowProject />}
                        />
                        <Route
                            path="projetos/editar/:id"
                            element={<EditProjectPage />}
                        />
                        <Route
                            path="projetos/editar_imagens/:id"
                            element={<EditProjectPhotosPage />}
                        />

                        <Route
                            path="fornecedores"
                            element={<IndexProviderPage />}
                        />
                        <Route
                            path="fornecedores/criar"
                            element={<CreateProviderPage />}
                        />
                        <Route
                            path="fornecedores/:id"
                            element={<AdmShowProvider />}
                        />
                        <Route
                            path="fornecedores/editar/:id"
                            element={<EditProviderPage />}
                        />
                        <Route
                            path="fornecedores/editar_imagens/:id"
                            element={<AddProviderImagePage />}
                        />

                        <Route
                            path="equipamentos"
                            element={<IndexEquipmentsPage />}
                        />
                        <Route
                            path="equipamentos/criar"
                            element={<CreateEquipmentPage />}
                        />
                        <Route
                            path="equipamentos/:id"
                            element={<AdmShowEquipment />}
                        />
                        <Route
                            path="equipamentos/editar/:id"
                            element={<EditEquipmentPage />}
                        />
                        <Route
                            path="equipamentos/editar_imagens/:id"
                            element={<EditEquipmentImagesPage />}
                        />

                        <Route path="" element={<IndexProjectPage />} />
                    </Route>
                )}

                <Route path="/login" element={<LoginPage />} />
                <Route path="*" element={<p>Página não encontrada</p>} />

                <Route path="/" element={<UserPages />}>
                    <Route path="" element={<Homepage />} />
                    <Route path="sobreNos" element={<AboutUsPage />} />
                    <Route path="fornecedores" element={<ProvidersPage />} />
                    <Route path="projetos" element={<IndexProjects />} />
                    <Route path="projetos/:id" element={<ShowProject />} />
                    <Route path="contato" element={<ContactPage />} />
                    <Route path="equipamentos" element={<EquipmentsPage />} />
                    <Route
                        path="equipamentos/venda"
                        element={<ForSaleEquipmentsPage />}
                    />
                    <Route
                        path="equipamentos/:id"
                        element={<ShowEquipmentPage />}
                    />
                    <Route
                        path="equipamentos/nossos"
                        element={<OurEquipmentsPage />}
                    />
                    <Route path="servicos" element={<ServicesPage />} />
                    <Route
                        path="servicos/infraestrutura"
                        element={<InfraestructurePage />}
                    />
                    <Route
                        path="servicos/construcaoCivil"
                        element={<CivilConstructionPage />}
                    />
                    <Route
                        path="servicos/terraplenagem"
                        element={<Landscaping />}
                    />
                    <Route
                        path="servicos/supressao"
                        element={<SupressionPage />}
                    />
                    <Route path="servicos/mineracao" element={<MiningPage />} />
                </Route>
            </Routes>
        </ScrollToTop>
    );
};

export default AllRoutes;