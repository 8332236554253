import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "../../../components/Carousel";
import { api } from "../../../services/api";
import {
    ButtonContainer,
    CustomForm,
    CustomHeader,
    InputContainer,
    RemoveImageButton,
    StyledButton,
    SubmitImageButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { Field } from "../../../styles/Adm/ShowPages/styles";

const EditEquipmentImagesPage = () => {
    let { id } = useParams();

    const [images, setImages] = useState([]);
    const [image, setImage] = useState();

    const [carouselShownIndex, setCarouselShownIndex] = useState(0);

    useEffect(() => {
        api.get(`/equipments/show/${id}`)
            .then((response) => {
                setImages(response.data.images_url);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    const formatCarouselContent = (images_url) => {
        return images_url.map((url) => {
            return { url: url, isImage: true };
        });
    };

    const addImage = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image", image);

        api.post(`equipments/add_image/${id}`, formData)
            .then((response) => {
                toast.success("foto adicionada");
                api.get(`/equipments/show/${id}`)
                    .then((response) => {
                        setImages(response.data.images_url);
                    })
                    .catch((e) => {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    });
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const deleteImg = async (e) => {
        console.log(images);
        e.preventDefault();

        api.delete(`/equipments/delete_image/${id}`, {
            params: { image_url: images[carouselShownIndex] },
        })
            .then(() => {
                toast.success("foto deletada");
                setImages(
                    images.filter((_img, i) => {
                        return i !== carouselShownIndex;
                    })
                );
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    return (
        <Container>
            <CustomHeader>Edite as imagens do Equipamento:</CustomHeader>
            <Field className="carousel-container">
                <h2> Deletar </h2>
                {images && images?.length !== 0 ? (
                    <Carousel
                        photos={formatCarouselContent(images)}
                        carouselFor={images[0]}
                        color={"var(--clr-primary)"}
                        setShownIndex={setCarouselShownIndex}
                    />
                ) : (
                    <p>Não há imagens a serem deletadas</p>
                )}
            </Field>

            <RemoveImageButton
                disabled={images == null || images.length === 0}
                onClick={deleteImg}
                endIcon={<AiFillDelete size={14} />}
            >
                Excluir
            </RemoveImageButton>

            <CustomForm onSubmit={addImage}>
                <InputContainer>
                    <label htmlFor="description">Adicionar imagem</label>
                    <p className="label-description">
                        Arraste uma imagem para a caixa pontilhada ou procure
                        uma em seu dispositivo
                    </p>
                    <input
                        accept="image/*"
                        placeholder="file"
                        type="file"
                        onChange={(event) => setImage(event.target.files[0])}
                    />
                </InputContainer>

                <SubmitImageButton
                    disabled={!image}
                    className="input-btn"
                    type="submit"
                >
                    Adicionar
                </SubmitImageButton>
            </CustomForm>

            <ButtonContainer className="button-container">
                <Link to={`/admin/equipamentos/${id}`}>
                    <StyledButton variant="contained">Concluir</StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default EditEquipmentImagesPage;
