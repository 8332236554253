import React from "react";
import { Section } from "../../../../styles/ServicesPages/styles.js";
import image1 from "../../../../assets/images/ServicesPage/Escavacao/Corte_de_talude_1.jpg";
import image2 from "../../../../assets/images/ServicesPage/Escavacao/escavacao.jpg";
import image3 from "../../../../assets/images/ServicesPage/Escavacao/Execucao_de_canal_1.jpg";

const MiningPage = () => {
    return (
        <Section>
            <div className="item-container">
                <h1> MINERAÇÃO </h1>
            </div>

            <div className="item-container">
                <p>
                    Nossos serviços à Mineração são confiáveis, possuindo alta
                    qualidade em suas execuções, combinando uma ampla gama de
                    equipamentos e máquinas modernas com profissões
                    especializados para garantir a entrega dos projetos a nossos
                    clientes com excelência.
                </p>
            </div>

            <div className="img-container">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
            </div>
        </Section>
    );
};

export default MiningPage;
