import React, { useEffect, useState } from "react";
import LinkInput from "../../../components/LinkInput";
import Creatable from "../../../components/Creatable";
import {
    ButtonContainer,
    Container,
    Form,
    Header,
    Input,
    InputContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getOptionsFromArrOfObjs } from "../../../services/utils/getOptions";

const CreateProjectPage = () => {
    const [linkId, setLinkId] = useState(0);

    const [links, setLinks] = useState({ link0: "" });

    const [project, setProject] = useState({});

    const [clientOptions, setClientOptions] = useState([]);
    const [placeOptions, setPlaceOptions] = useState([]);

    const navigate = useNavigate();

    const orderValueFunction = (a, b) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        // a must be equal to b
        return 0;
    };

    useEffect(() => {
        async function fetchProjectsClientAndPlace() {
            await api
                .get(`/projects/`)
                .then(({ data }) => {
                    let foundClientOptions = getOptionsFromArrOfObjs(
                        data,
                        "client_name"
                    );
                    let foundPlaceOptions = getOptionsFromArrOfObjs(
                        data,
                        "place"
                    );

                    setClientOptions(
                        foundClientOptions.sort(orderValueFunction)
                    );
                    setPlaceOptions(foundPlaceOptions.sort(orderValueFunction));
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
        fetchProjectsClientAndPlace();
    }, []);

    const validDate = (date) => {
        if (date == null) {
            return {
                status: "ok",
                value: undefined,
            };
        }

        if (date?.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
            const [year, month, day] = date.split("/").reverse();
            if (day > 31 || day < 1) {
                return {
                    status: "day",
                };
            } else if (month > 12 || month < 1) {
                return {
                    status: "month",
                };
            }

            return {
                status: "ok",
                value: new Date(year, month, day),
            };
        }
        return {
            status: "invalid",
        };
    };

    const linkCode = (link) => {
        if (link.match(/^https:\/\/www\.youtube\.com\/watch\?v=/)) {
            return link.slice(32);
        } else if (link.match(/^https:\/\/youtu\.be\//)) {
            return link.slice(17);
        }
        return false;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, description, start_date, end_date, place, client_name } =
            project;
        const youtube_links = [];
        let linksOk = true;
        Object.keys(links)
            .filter((link) => links[link] !== "norender" && links[link] !== "")
            .forEach((link) => {
                const code = linkCode(links[link]);
                if (code) {
                    youtube_links.push(code);
                } else {
                    linksOk = false;
                }
            });
        const validateStartDate = validDate(start_date);
        const validateEndDate = validDate(end_date);

        if (!name || name === "") {
            toast.error("Coloque um nome");
        } else if (!description || description === "") {
            toast.error("Coloque uma descrição");
        } else if (!linksOk) {
            toast.error(
                "Coloque um link válido do youtube em todos os campos preenchidos de link"
            );
        } else if (validateStartDate.status !== "ok") {
            switch (validateStartDate.status) {
                case "invalid":
                    toast.error(
                        "Coloque a data de início no formato dd/mm/aaaa"
                    );
                    break;
                case "day":
                    toast.error(
                        "Coloque um dia válido(1-31) na data de início"
                    );
                    break;
                case "month":
                    toast.error(
                        "Coloque um mês válido(1-12) na data de início"
                    );
                    break;
                default:
                    toast.error(validateStartDate.status);
                    break;
            }
        } else if (validateEndDate.status !== "ok") {
            switch (validateEndDate.status) {
                case "invalid":
                    toast.error(
                        "Coloque a data de término no formato dd/mm/aaaa"
                    );
                    break;
                case "day":
                    toast.error(
                        "Coloque um dia válido(1-31) na data de término"
                    );
                    break;
                case "month":
                    toast.error(
                        "Coloque um mes válido(1-12) na data de término"
                    );
                    break;
                default:
                    toast.error(validateEndDate.status);
                    break;
            }
        } else if (
            validateStartDate.value !== validateEndDate.value &&
            validateStartDate.value > validateEndDate.value
        ) {
            toast.error("Coloque uma data de término depois da data de início");
        } else if (!place || place === "") {
            toast.error("Coloque um local");
        } else if (!client_name || client_name === "") {
            toast.error("Coloque o nome do cliente do projeto");
        } else {
            await api
                .post("/projects/create", {
                    name,
                    description,
                    start_date,
                    end_date,
                    place,
                    client_name,
                    youtube_links,
                })
                .then((response) => {
                    toast.success("Projeto Criado com Sucesso");
                    navigate(`/admin/projetos/${response.data.id}`);
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
    };

    return (
        <Container>
            <Header>
                <h1>Adicionar Projeto</h1>
                <h2>Preencha todos os campos para criar um projeto</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Projeto</label>
                    <Input
                        placeholder="Nome do projeto*"
                        id="name"
                        onChange={(e) =>
                            setProject({ ...project, name: e.target.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">Descrição do projeto</p>
                    <Input
                        placeholder="Descrição*"
                        multiline
                        minRows={4}
                        id="description"
                        onChange={(e) =>
                            setProject({
                                ...project,
                                description: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label>Vídeos do Youtube</label>
                    <p className="label-description">
                        Adicione, edite ou delete links
                    </p>
                    <Input
                        placeholder="Link"
                        onChange={(e) => {
                            setLinks({ ...links, link0: e.target.value });
                        }}
                    />
                    {Object.keys(links)
                        .filter((link) => {
                            return (
                                link !== "link0" && links[link] !== "norender"
                            );
                        })
                        .map((link) => {
                            return (
                                <LinkInput
                                    link={link}
                                    key={link}
                                    onChangeFunction={(e) => {
                                        setLinks({
                                            ...links,
                                            [link]: e.target.value,
                                        });
                                    }}
                                    onRemoveFunction={() => {
                                        setLinks({
                                            ...links,
                                            [link]: "norender",
                                        });
                                    }}
                                />
                            );
                        })}
                    <ButtonContainer>
                        <StyledButton
                            onClick={() => {
                                setLinks({
                                    ...links,
                                    ["link" + (linkId + 1)]: "",
                                });
                                setLinkId(linkId + 1);
                            }}
                            size="medium"
                            variant="contained"
                            className="smaller-button"
                        >
                            Adicionar link
                        </StyledButton>
                    </ButtonContainer>
                </InputContainer>

                <InputContainer>
                    <label htmlFor="local">Local</label>
                    <p className="label-description">Local do projeto</p>
                    <Creatable
                        options={placeOptions}
                        placeholder="Local*"
                        id="local"
                        onChange={(opt) =>
                            setProject({ ...project, place: opt.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="client">Cliente</label>
                    <p className="label-description">Nome do Cliente</p>
                    <Creatable
                        options={clientOptions}
                        placeholder="Nome do Cliente*"
                        id="client"
                        onChange={(opt) =>
                            setProject({ ...project, client_name: opt.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="start-date">Data de início</label>
                    <p className="label-description">
                        Preencha o campo com a data de inicío do projeto
                    </p>
                    <Input
                        placeholder="Data de início"
                        id="start-date"
                        onChange={(e) =>
                            setProject({
                                ...project,
                                start_date: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="end-date">Data de término</label>
                    <p className="label-description">
                        Preencha o campo com a data prevista para o término do
                        projeto
                    </p>
                    <Input
                        placeholder="Data de término"
                        id="end-date"
                        onChange={(e) =>
                            setProject({ ...project, end_date: e.target.value })
                        }
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <StyledButton
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    Adicionar
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default CreateProjectPage;