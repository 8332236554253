import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import { PageWrapper, ProvidersWrapper } from "./styles";

export const ProvidersPage = () => {
    const [providers, setProviders] = useState([]);

    useEffect(() => {
        api.get("/providers")
            .then((response) => setProviders(response.data))
            .catch((er) => toast.error(er.message));
    }, []);

    return (
        <PageWrapper>
            <h1>FORNECEDORES</h1>
            <p>
                Uma boa política de convivência é um dos trunfos para quem quer
                ir mais longe em qualquer projeto, principalmente nos negócios.
                Além de construir alianças e promover o crescimento dos
                negócios, a comunicação deve ser sincera e objetiva. Sabendo
                disso, a Mark's Engenharia vê seus fornecedores como verdadeiros
                parceiros, com diálogo transparente e relacionamentos
                duradouros. Aqui estão alguns de nossos fornecedores:
            </p>

            <ProvidersWrapper>
                {providers.map((provider) => {
                    return (
                        <a
                            key={provider.id}
                            href={
                                provider.website ? provider.website : undefined
                            }
                            target="_blank"
                            className="provider-link"
                            rel="noreferrer"
                        >
                            <img
                                src={`${apiPhoto.defaults.baseURL}${provider.logo_url}`}
                                alt={provider.name}
                            />
                        </a>
                    );
                })}
            </ProvidersWrapper>

            <p className="center">
                Buscamos formar parcerias com fornecedores que adotem as
                melhores práticas de administração.
                <br />
                <Link
                    to="/contato"
                    state={{ props: { defaultContactOption: 4 } }}
                >
                    Seja nosso fornecedor
                </Link>
            </p>
        </PageWrapper>
    );
};
