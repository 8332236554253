import React from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "../../components/AdminSidebar";
import AdminPageLayout from "./styles.js";

const AdminPages = () => {
	return (
		<AdminPageLayout>
			<AdminSidebar className="sidebar" />
			<div className="page-content-wrapper">
				<Outlet />
			</div>
		</AdminPageLayout>
	);
};

export default AdminPages;
