import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Creatable from "../../../components/Creatable";
import { api } from "../../../services/api";
import {
    Container,
    Header,
    Form,
    InputContainer,
    Input,
    ButtonContainer,
    StyledButton,
    StyledCheckbox,
} from "../../../styles/Adm/Create&EditPages/styles";
import { getOptionsFromArrOfObjs } from "../../../services/utils/getOptions";

const CreateEquipmentPage = () => {
    const [equipment, setEquipment] = useState({});

    const [nameOptions, setNameOptions] = useState([]);
    const [placeOptions, setPlaceOptions] = useState([]);

    const navigate = useNavigate();

    const orderValueFunction = (a, b) => {
        return a.value > b.value;
    };

    useEffect(() => {
        function fetchEquipmentsNameAndPlace() {
            api.get(`/equipments/`)
                .then(({ data }) => {
                    let foundNameOptions = getOptionsFromArrOfObjs(
                        data,
                        "name"
                    );
                    let foundPlaceOptions = getOptionsFromArrOfObjs(
                        data,
                        "place"
                    );

                    setNameOptions(foundNameOptions.sort(orderValueFunction));
                    setPlaceOptions(foundPlaceOptions.sort(orderValueFunction));
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }

        fetchEquipmentsNameAndPlace();
    }, []);

    const handlePrice = (value) => {
        value = value.replace(",", ".");
        if (isNaN(value)) {
            return NaN;
        }
        if (!Number.isInteger(value * 100)) {
            return NaN;
        }
        return Number.parseInt(value * 100);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {
            brand,
            name,
            version,
            series,
            year,
            description,
            place,
            hour_meter,
            price,
            contact_info,
        } = equipment;
        let { for_sale } = equipment;

        if (!name || name === "") {
            toast.error("Coloque um nome");
        } else if (!brand || brand === "") {
            toast.error("Coloque uma marca");
        } else if (!version || version === "") {
            toast.error("Coloque uma modelo");
        } else if (!series || series === "") {
            toast.error("Coloque uma série");
        } else if (!year || year === "") {
            toast.error("Coloque um ano");
        } else if (
            hour_meter != null &&
            hour_meter !== "" &&
            (isNaN(hour_meter) ||
                !Number.isInteger(Number.parseInt(hour_meter)) ||
                hour_meter < 0)
        ) {
            toast.error("Coloque um horímetro válido ou nenhum horímetro");
        } else if (
            price != null &&
            (isNaN(price) || !Number.isInteger(price) || price < 0)
        ) {
            toast.error("Coloque um preço válido ou não coloque preço");
        } else {
            if (!for_sale) {
                for_sale = false;
            }

            await api
                .post("/equipments/create", {
                    brand,
                    name,
                    version,
                    series,
                    year,
                    description,
                    place,
                    hour_meter,
                    for_sale,
                    price,
                    contact_info,
                })
                .then((response) => {
                    toast.success("Equipamento criado com Sucesso");
                    navigate(`/admin/equipamentos/${response.data.id}`);
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
    };

    return (
        <Container>
            <Header>
                <h1>Adicionar Equipamento</h1>
                <h2>Preencha todos os campos para criar um equipamento</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Equipamento</label>
                    <Creatable
                        options={nameOptions}
                        placeholder="Nome do equipamento*"
                        id="name"
                        onChange={(opt) =>
                            setEquipment({ ...equipment, name: opt.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="brand">Marca</label>
                    <p className="label-description">
                        Preencha o campo com a marca do equipamento
                    </p>
                    <Input
                        placeholder="Marca*"
                        id="brand"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                brand: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="year">Ano</label>
                    <p className="label-description">
                        Preencha o campo com o ano de fabricação do equipamento
                    </p>
                    <Input
                        placeholder="Ano*"
                        id="year"
                        onChange={(e) =>
                            setEquipment({ ...equipment, year: e.target.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="version">Modelo</label>
                    <p className="label-description">
                        Preencha o campo com a modelo do equipamento
                    </p>
                    <Input
                        placeholder="Modelo*"
                        id="version"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                version: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="series">Série</label>
                    <p className="label-description">
                        Preencha o campo com a série do equipamento
                    </p>
                    <Input
                        placeholder="Série*"
                        id="series"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                series: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="for_sale">À venda</label>
                    <p className="label-description">
                        Marque se deseja que o equipamento esteja a venda.{" "}
                        <br />
                        <small>
                            Obs: Os campos seguintes serão usados para tais
                            equipamentos.
                        </small>
                    </p>
                    <StyledCheckbox
                        id="for_sale"
                        size="large"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                for_sale: e.target.checked,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">
                        Dê uma descrição do equipamento.
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Descrição"
                        multiline
                        minRows={4}
                        id="description"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                description: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="local">Local</label>
                    <p className="label-description">
                        Onde o equipamento se encontra
                    </p>
                    <Creatable
                        disabled={!equipment.for_sale}
                        options={placeOptions}
                        placeholder="Local"
                        id="local"
                        onChange={(opt) =>
                            setEquipment({ ...equipment, place: opt.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="horimeter">Horímetro</label>
                    <p className="label-description">
                        Preencha o campo com o horímetro do equipamento
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Horímetro"
                        id="horimeter"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                hour_meter: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="price">Preço</label>
                    <p className="label-description">
                        Preencha o campo com o preço, caso seja definido. Deixe
                        em branco se for a combinar.
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Preço"
                        id="price"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                price: handlePrice(e.target.value),
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="contact_info">Contato</label>
                    <p className="label-description">
                        Escreva como contatar sobre o equipamento.
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Contato"
                        id="contact_info"
                        onChange={(e) =>
                            setEquipment({
                                ...equipment,
                                contact_info: e.target.value,
                            })
                        }
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <StyledButton
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    Adicionar
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default CreateEquipmentPage;
