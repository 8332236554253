import styled from "styled-components";

export const Container = styled.div`
	z-index: 999;
	height: 100%;
	min-height: 100vh;
	width: max-content;

	#toggle {
		display: none;
	}

	#wrap {
		width: max-content;
		padding-inline: 1rem;
		float: left;
		background-color: var(--clr-secondary);
		padding-top: 4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		color: black;
		min-height: 100vh;
		height: 100%;

		p {
			font-family: "Roboto";
		}

		img {
			height: 75px;
			width: auto;
			margin-bottom: 2.5rem;
		}

		#close-icon {
			display: none;
		}

		ul {
			list-style: none;
		}

		.selector,
		li {
			font-size: 1.5rem;
			margin: 1.2rem;
			margin-bottom: 1.5rem;
		}

		.selector-name {
			font-size: 1rem;
		}

		.selector-item {
			text-decoration: none;
			color: black;
		}

		#exit {
			border: none;
			background: none;
			color: inherit;
			margin-top: 1rem;
			justify-content: space-between;
			cursor: pointer;
			width: 50px;

			span {
				margin-right: 5px;
				font-size: 1rem;
			}
		}
	}

	@media only screen and (max-width: 890px) {
		#wrap {
			width: 225px;

			.selector,
			li {
				font-size: 1.2rem;
				margin: 1.2rem;
				margin-bottom: 1.5rem;
			}
		}
	}

	@media only screen and (max-width: 700px) {
		transform: translateX(-100%);

		#toggle {
			cursor: pointer;
			font-size: 3rem;
			display: block;
			position: fixed;
			background-color: var(--clr-secondary);
			color: black;
		}

		#wrap {
			padding-top: 0;
			#close-icon {
				cursor: pointer;
				margin-left: -12rem;
				font-size: 3.2rem;
				display: block;
			}
		}

		.nav-menu {
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition: 850ms;
		}

		.nav-menu.active {
			transform: translateX(0);
			transition: 350ms;
		}
	}
`;
