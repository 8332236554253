import React, { useEffect, useState } from "react";
import { PageWrapper } from "../styles";
import { api } from "../../../../services/api";
import { Carousel } from "../../../../components/Carousel";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const ShowEquipmentPage = () => {
    const { id } = useParams();
    const [equipment, setEquipment] = useState({});

    useEffect(() => {
        api.get("/equipments/show/" + id)
            .then(({ data }) => {
                console.log(data);
                setEquipment(data);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    const formatEquipmentImages = ({ images_url }) =>
        images_url?.map((url) => {
            return { isImage: true, url };
        });

    return (
        <PageWrapper className="inverted-colors">
            <h1>{equipment?.name}</h1>

            {equipment && equipment.images_url && equipment.images_url[0] && (
                <Carousel
                    photos={formatEquipmentImages(equipment)}
                    carouselFor={equipment?.name}
                />
            )}
            <p>{equipment?.description}</p>

            <p>
                <b>Marca</b>: {equipment.brand} &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;
                <b>Modelo:</b> {equipment.version} &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;
                <b>Série:</b> {equipment.series} &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;
                <b>Ano:</b> {equipment.year}
            </p>

            <p>
                <b>Se encontra em:</b> {equipment.place} &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
                <b>Horímetro:</b> {equipment.hour_meter} &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
                <b>Preço: </b>
                {equipment.price == null
                    ? "R$ " + (equipment.price / 100).toFixed(2)
                    : "A combinar"}
            </p>
        </PageWrapper>
    );
};

export default ShowEquipmentPage;
