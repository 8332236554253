import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/api";
import {
    ButtonContainer,
    Container,
    Form,
    Header,
    Input,
    InputContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";

const CreateMemberPage = () => {
    const [member, setMember] = useState({});

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, contact_info, description } = member;
        if (!name || name === "") {
            toast.error("Coloque um nome");
        } else if (!contact_info || contact_info === "") {
            toast.error("Coloque um contato");
        } else if (!description || description === "") {
            toast.error("Coloque uma descrição");
        } else {
            await api
                .post("/members/create", {
                    name,
                    contact_info,
                    description,
                })
                .then((response) => {
                    toast.success("Membro Criado com Sucesso");
                    navigate(`/admin/membros/${response.data.id}`);
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
    };

    return (
        <Container>
            <Header>
                <h1>Adicionar Membro</h1>
                <h2>Preencha todos os campos para criar um membro</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Membro</label>
                    <Input
                        placeholder="Nome do membro*"
                        id="name"
                        onChange={(e) =>
                            setMember({ ...member, name: e.target.value })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="contact_info">Contato</label>
                    <p className="label-description">
                        Escreva o contato do membro e, se necessário, o meio de
                        contato
                    </p>
                    <Input
                        placeholder="Contato*"
                        id="contact_info"
                        onChange={(e) =>
                            setMember({
                                ...member,
                                contact_info: e.target.value,
                            })
                        }
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">
                        Preencha com a descrição que deve aparecer no site. Pode
                        ser uma mais extensa ou apenas o papel que desempenha na
                        empresa.
                    </p>
                    <Input
                        placeholder="Descrição*"
                        multiline
                        minRows={4}
                        id="description"
                        onChange={(e) =>
                            setMember({
                                ...member,
                                description: e.target.value,
                            })
                        }
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <StyledButton
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    fullWidth
                >
                    {" "}
                    Adicionar{" "}
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default CreateMemberPage;
