import React, { useEffect, useState } from "react";
import { PageWrapper, HeaderArea } from "../styles";
import { api } from "../../../../services/api";
import AvaliableEquipmentsTable from "./AvaliableEquipmentsTable";
import toast from "react-hot-toast";

const OurEquipmentsPage = () => {
	const [equipments, setEquipments] = useState([]);

	useEffect(() => {
		api
			.get("/equipments")
			.then((response) => {
				console.log(response.data);
				setEquipments(response.data.filter((eq) => !eq.for_sale));
			})
			.catch((er) => {
				toast.error(er.message);
			});
	}, []);

	return (
		<PageWrapper className="inverted-colors">
			<HeaderArea>
				<h1> NOSSOS EQUIPAMENTOS</h1>
			</HeaderArea>

			<p>
				Possuímos diversificada frota própria com cerca de 313 equipamentos
				entre pesados e leves, que possibilitam a execução de todas as fases dos
				mais diversos serviços inerentes à área de atuação da empresa.
			</p>

			<AvaliableEquipmentsTable equipments={equipments} />
		</PageWrapper>
	);
};

export default OurEquipmentsPage;
