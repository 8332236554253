export const getOptionsFromArrOfObjs = (arr_of_objs, obj_prop_name) => {
	return arr_of_objs
		.filter(
			(obj, i) =>
				// if is the first ocurrence of this name
				arr_of_objs.findIndex(
					(another_obj) => another_obj[obj_prop_name] === obj[obj_prop_name]
				) === i
		)
		.map((obj) => {
			return {
				value: obj[obj_prop_name],
				label: obj[obj_prop_name],
			};
		});
};
