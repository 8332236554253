import styled from "styled-components";

export const Container = styled.div`
	.photos {
		display: flex;
		margin: auto;
		padding-top: 2rem;
		width: 100%;

		.img-container {
			margin: auto;
			width: 80%;
			img,
			iframe {
				width: 100%;
				height: var(--index-big-img-height);
				object-fit: cover;
				border: initial;
			}
		}

		.arrow {
			align-self: center;
			margin: 0 2rem;
		}
	}

	@media only screen and (max-width: 850px) {
		.photos {
			.arrow {
				margin: 0 1rem;
			}
		}
	}
	@media only screen and (max-width: 470px) {
		position: relative;
		.photos {
			.img-container {
				width: 100%;
			}
			.arrow {
				margin: 0;
				position: absolute;
				top: calc(var(--index-big-img-height) + 2rem);
				width: 2rem;
			}

			.left {
				left: 0;
			}
			.right {
				right: 0;
			}
		}
	}
`;
