import { Container } from "./styles";
import { FaSignOutAlt, FaUserFriends, FaProjectDiagram } from "react-icons/fa";
import { GiMineTruck, GiHandTruck } from "react-icons/gi";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosClose } from "react-icons/io";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../contexts/useAdminContext";

const AdminSidebar = () => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    const { logout } = useAdminContext();

    return (
        <Container>
            <GiHamburgerMenu id="toggle" onClick={showSidebar} />

            <div id="wrap" className={sidebar ? "nav-menu active" : "nav-menu"}>
                <IoIosClose id="close-icon" onClick={showSidebar} />

                <img src="" className="sidebar-item" alt="" />

                <div className="sidebar-item" id="welcome">
                    <p>Bem vindo, Admin!</p>
                </div>

                <button
                    className="sidebar-item"
                    id="exit"
                    onClick={() => {
                        logout();
                    }}
                >
                    <span>Sair</span> <FaSignOutAlt />
                </button>

                <ul className="selector">
                    <p className="selector-name">Gestão:</p>
                    <li onClick={showSidebar}>
                        <Link to="./projetos" className="selector-item">
                            <FaProjectDiagram /> <span>Projetos</span>
                        </Link>
                    </li>
                    <li onClick={showSidebar}>
                        <Link to="./membros" className="selector-item">
                            <FaUserFriends /> <span>Membros</span>
                        </Link>
                    </li>
                    <li onClick={showSidebar}>
                        <Link to="./equipamentos" className="selector-item">
                            <GiMineTruck /> <span>Equipamentos</span>
                        </Link>
                    </li>
                    <li onClick={showSidebar}>
                        <Link to="./fornecedores" className="selector-item">
                            <GiHandTruck /> <span>Fornecedores</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </Container>
    );
};

export default AdminSidebar;
