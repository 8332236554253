import React from "react";
import { Container } from "./styles";
import ProjectImage from "../../assets/images/image9.png";
import { Link } from "react-router-dom";
import { apiPhoto } from "../../services/api";

const ProjectCard = ({ project }) => {
    const formatDate = (date) =>
        date?.slice(0, 10).split("-").reverse().join("/");

    return (
        <Container>
            <div className="project-logo">
                <Link to={`/projetos/${project.id}`}>
                    <img
                        src={
                            project.logo_url
                                ? `${apiPhoto.defaults.baseURL}${project.logo_url}`
                                : ProjectImage
                        }
                        alt={`Logo do projeto ${project.name}`}
                    />
                </Link>
            </div>
            <div className="project-info">
                <Link to={`/projetos/${project.id}`}>
                    <h4>{project?.name}</h4>
                </Link>
                <p>{project?.description}</p>
                <p>{project?.place}</p>
                <p>
                    {formatDate(project?.start_date) +
                        " - " +
                        formatDate(project?.end_date)}
                </p>
            </div>
        </Container>
    );
};

export default ProjectCard;
