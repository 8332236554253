import { BottomNavigation } from "@mui/material";
import styled from "styled-components";


export const StyledBottomNavigation = styled(BottomNavigation)`
    && {
        background-color: transparent;
        display: flex;
        flex-wrap: wrap;
        padding: 0 10%;
        height: max-content;
        
        button {
            max-width: 1rem;
            padding: .7rem 0;
        }

        .Mui-selected {
            color: #fff;
        }
    }
`