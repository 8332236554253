import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos} from 'react-icons/md'
import styled from 'styled-components'

export const ArrowBack = styled(MdOutlineArrowBackIosNew)`
    && {
        cursor: pointer;
    }
`

export const ArrowFront = styled(MdOutlineArrowForwardIos)`
    && {
        cursor: pointer;
    }
`