import styled from "styled-components";
import { MdClear } from "react-icons/md";
import TextField from "../Input";
import Button from "../Button";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	div.removable {
		flex: 3;
	}
`;

export const ClearButton = styled(Button)`
	&& {
		align-self: flex-end;
		flex: 0;
		padding-bottom: 1rem;
		background-color: transparent;
		min-width: max-content;
		&:hover {
			background-color: transparent;
		}
	}
`;

export const RemoveIcon = styled(MdClear)`
	&& {
		color: #000;
	}
`;

export const Input = styled(TextField)`
	&& {
		margin-top: 3rem;
		input,
		textarea {
			font-size: var(--create-input-font-size);
		}
	}
`;
