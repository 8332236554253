import { Container } from "./styles";

const AvaliableEquipmentsTable = ({ equipments }) => {
	equipments.sort((eq1, eq2) => eq1.model > eq2.model);
	equipments.sort((eq1, eq2) => eq1.year > eq2.year);

	return (
		<Container>
			<table>
				<tr>
					<th>Índice</th>
					<th>Nome</th>
					<th>Modelo</th>
					<th>Série</th>
					<th>Marca</th>
					<th>Ano</th>
				</tr>
				{equipments.map((equipment, i) => {
					return (
						<tr>
							<td>{i + 1}</td>
							<td>{equipment.name}</td>
							<td>{equipment.version}</td>
							<td>{equipment.series}</td>
							<td>{equipment.brand}</td>
							<td>{equipment.year}</td>
						</tr>
					);
				})}
			</table>
		</Container>
	);
};

export default AvaliableEquipmentsTable;
