import React, { useState } from "react";
import { useAdminContext } from "../../contexts/useAdminContext";
import { Container, InputField, StyledButton } from "./styles";
import InputAdornment from "@mui/material/InputAdornment";
import { FaUserCircle } from "react-icons/fa";
import { BsKeyFill } from "react-icons/bs";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LoginPage = () => {
    const navigate = useNavigate();

    const [admin, setAdmin] = useState(undefined);
    const { login } = useAdminContext();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!admin || !admin.email || admin.email === "") {
            toast.error("Coloque um email!");
            return;
        }
        if (!admin.password) {
            toast.error("Coloque uma senha!");
            return;
        }
        const valid_login = await login(admin);

        if (valid_login) {
            navigate("/admin/projetos");
        }
    };

    return (
        <Container>
            <h1>LOGIN</h1>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {/* <input placeholder='email' onChange={(value) => setAdmin({...admin, email: value.target.value})}/>
          <input placeholder='senha' onChange={(value) => setAdmin({...admin, password: value.target.value})} type='password'/> */}
                    <Box mt={2}>
                        <InputField
                            onChange={(value) =>
                                setAdmin({
                                    ...admin,
                                    email: value.target.value,
                                })
                            }
                            label="E-mail"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <FaUserCircle size={"1.3rem"} />
                                    </InputAdornment>
                                ),
                            }}
                        ></InputField>
                    </Box>
                    <Box mt={2}>
                        <InputField
                            onChange={(value) =>
                                setAdmin({
                                    ...admin,
                                    password: value.target.value,
                                })
                            }
                            label="Senha"
                            type="password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BsKeyFill size={"1.5rem"} />
                                    </InputAdornment>
                                ),
                            }}
                        ></InputField>
                    </Box>
                    <StyledButton type="submit">ENTRAR</StyledButton>
                </form>
            </div>
        </Container>
    );
};

export default LoginPage;
