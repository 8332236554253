import { api } from "../../../services/api";
import React, { useEffect, useState } from "react";
import {
    Container,
    ElementContainer,
    Section,
    StyledButton,
} from "./../../../styles/Adm/IndexPages/styles";

import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const IndexEquipmentsPage = () => {
    const [equipments, setEquipments] = useState([]);

    useEffect(() => {
        api.get("/equipments/")
            .then((response) => {
                setEquipments(response.data);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, []);

    const deleteEquipment = (id) => {
        if (!window.confirm("Tem certeza que quer excluir esse equipamento?")) {
            return;
        }

        api.delete(`/equipments/delete/${id}`)
            .then(() => {
                toast.success("Equipamento deletado");
                setEquipments((equipments) =>
                    equipments.filter((eq) => eq.id !== id)
                );
            })
            .catch((er) => {
                switch (er?.response?.status) {
                    case 401:
                        toast.error(
                            "O usuário não pode ser autenticado. Caso realmente seja um administrador, tente sair e entrar novamente na conta."
                        );
                        break;

                    default:
                        toast.error(er.message);
                        break;
                }
            });
    };

    const handlePrice = (price) => {
        var value = (price / 100).toFixed(2).toString();
        value = value.replace(".", ",");
        return value;
    };

    return (
        <>
            <Section>
                <div className="title-container">
                    <h1> Gerencie seus Equipamentos </h1>
                </div>

                <div className="head-container">
                    <div className="table-titles">
                        <div className="containers">
                            <h2>
                                {" "}
                                Nome
                                <span className="null-on-mobile">
                                    {" "}
                                    do Equipamento
                                </span>{" "}
                            </h2>
                        </div>
                        <div className="containers">
                            <h2> Marca </h2>
                        </div>
                        <div className="containers">
                            <h2> Ano </h2>
                        </div>
                        <div className="containers null-on-mobile">
                            <h2> Preço </h2>
                        </div>
                        <div className="containers">
                            <h2> Opções </h2>
                        </div>
                    </div>
                </div>

                <Container>
                    {equipments.map((item, index) => (
                        <ElementContainer key={item.id}>
                            <div className="containers">
                                <Link to={`./${item.id}`}>
                                    <span> {item.name} </span>
                                </Link>
                            </div>
                            <div className="containers">
                                <Link to={`./${item.id}`}>
                                    <span> {item.brand} </span>
                                </Link>
                            </div>
                            <div className="containers">
                                <Link to={`./${item.id}`}>
                                    <span> {item.year} </span>
                                </Link>
                            </div>
                            <div className="containers null-on-mobile">
                                <span> R$ {handlePrice(item.price)} </span>
                            </div>
                            <div className="containers">
                                <Link to={`./editar/${item.id}`}>
                                    <StyledButton
                                        variant="contained"
                                        className="small-font"
                                        endIcon={<MdEdit size={14} />}
                                    >
                                        Editar
                                    </StyledButton>
                                </Link>
                                <StyledButton
                                    variant="contained"
                                    type="button"
                                    className="small-font"
                                    onClick={() => deleteEquipment(item.id)}
                                    endIcon={<AiFillDelete size={13} />}
                                >
                                    Excluir
                                </StyledButton>
                            </div>
                        </ElementContainer>
                    ))}
                </Container>

                <Link to="./criar">
                    <Button>Adicionar Equipamento</Button>
                </Link>
            </Section>
        </>
    );
};
export default IndexEquipmentsPage;