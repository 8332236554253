import React from "react";
import { Section } from "../../../../styles/ServicesPages/styles.js";
import image1 from "../../../../assets/images/ServicesPage/Canteiro/Canteiro_KM_487.jpg";
import image2 from "../../../../assets/images/ServicesPage/Concreto/Escada_Hidraulica.jpg";
import image3 from "../../../../assets/images/ServicesPage/Concreto/concreto3.jpg";

const CivilConstructionPage = () => {
    return (
        <Section>
            <div className="item-container">
                <h1> CONSTRUÇÃO CIVIL </h1>
            </div>

            <div className="item-container">
                <p>
                    A Mark’s Engenharia conta com profissionais capacitados e
                    diversas obras entregues durante esses mais de 25 anos.
                    Oferecemos serviços preliminares (demolições, terraplenagem,
                    entre outros), obras estruturais em geral e reformas,
                    drenagem, entre diversas outras atividades. Nossas obras são
                    sempre executadas com segurança, agilidade e qualidade, a
                    fim de atender a máxima satisfação de nossos clientes.
                </p>
            </div>

            <div className="img-container">
                <img src={image1} alt="" />
                <img src={image2} alt="" className="bigger" />
                <img src={image3} alt="" />
            </div>
        </Section>
    );
};

export default CivilConstructionPage;
