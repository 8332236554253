import styled from "styled-components";

export const FilterWrapper = styled.div`
	transition: 0.4s ease-out;
	width: 25rem;
	max-width: 90vw;
	--button-width: 25rem;
	font-size: calc(var(--font-size-text) - 0.3rem);
	h3 {
		font-weight: 500;
	}
`;

export const FieldTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1rem;
	padding-right: 4rem;
	transition: 0.1s;
`;

export const FieldOptionsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem 1rem 4rem;
	margin-top: -1rem;
	margin-bottom: 0rem;
	opacity: 0.01;
	transition: 0.4s ease-out;

	&[data-visible="false"] {
		position: absolute;
		pointer-events: none;
	}

	&[data-visible="true"] {
		opacity: 1;
	}
`;

export const OptionWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: 4rem;
`;
