import styled from "styled-components";

export const Container = styled.div`
	overflow-y: scroll;
	margin-inline: calc(var(--reverse-section-inline-padding));
	padding-inline-start: 8px;

	table {
		width: 80%;
		margin-inline: auto;
		border-collapse: collapse;

		tr {
			width: 100%;
		}

		th {
			border: 1.5px solid black;
			background-color: var(--clr-secondary);
			font-family: var(--font-family-tertiary);
			font-size: calc(var(--font-size-text) / 2 + 5px);
		}

		td {
			border: 1.5px solid black;
			text-align: center;
			background-color: white;
			font-size: calc(var(--font-size-text) / 2 + 4px);
		}

		th,
		td {
			padding: 15px;
			text-align: left;
		}
	}
`;
