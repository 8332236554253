import React from 'react'
import { StyledRadio } from './styles'

const Radio = ({children, ...rest}) => {
  return (
    <StyledRadio {...rest}>
        {children}
    </StyledRadio>
  )
}


export default Radio