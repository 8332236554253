import React, { useEffect } from "react";
import { Container, SmallFieldText, LargeFieldText } from "./styles";
import { useState } from "react";
import Tabs from "../Tabs/index.js";
import { Tab } from "@mui/material";
import { apiMailer } from "../../services/api";
import sendbutton from "../../assets/images/sendbutton.png";
import { useRef } from "react";
import toast from "react-hot-toast";

const Mailer = ({ defaultOption }) => {
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [document, setDocument] = useState([]);

	const contactRef = useRef();
	const [selectValue, setSelectValue] = useState(defaultOption || 0);
	useEffect(() => {
		if (defaultOption && contactRef.current) {
			contactRef.current.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
	}, [defaultOption]);

	const handleTabChange = (event, newValue) => {
		setSelectValue(newValue);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (message === "") {
			toast.error("Coloque uma mensagem válida");
			return;
		}

		const formData = new FormData();

		if (document[0]) {
			formData.append("document", document[0]);
		}

		const subject = selectValue

		formData.append("name", name);
		formData.append("message", message);
		formData.append("email", email);
		formData.append("subject", subject);

		const config = {
			headers: { "content-type": "multipart/form-data" },
		};

		try {
			const response = await apiMailer.post("/contact_us", formData, config);

			if (response.data) {
				toast.success("Mensagem enviada!", "success");
			}
		} catch (error) {
			toast.error(error.message, "error");
		}
	};

	return (
		<Container ref={contactRef}>
			<form onSubmit={handleSubmit}>
				<div className="small-text-container">
					<SmallFieldText
						className="small-text"
						label="Nome"
						placeholder="Nome"
						onChange={(e) => setName(e.target.value)}
					/>
					<SmallFieldText
						className="small-text"
						label="E-mail"
						placeholder="E-mail"
						onChange={(e) => setEmail(e.target.value)}
					/>
					<div className="pdf-field">
						<input
							aria-label="Adicione um pdf"
							accept=".pdf"
							onChange={(event) => setDocument(event.target.files)}
							type="file"
							className="document_input"
							id="document_input"
						/>
					</div>
				</div>

				<div className="lower-line">
					<LargeFieldText
						className="message"
						multiline
						rows={10}
						label="Mensagem"
						placeholder={
							selectValue === 0
								? "Deixe sua reclamação aqui"
								: selectValue === 1
								? "Deixe sua sugestão aqui"
								: selectValue === 2
								? "Faça sua denúncia"
								: selectValue === 3
								? "Trabalhe conosco"
								: "Seja nosso fornecedor"
						}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<Tabs
						value={selectValue}
						onChange={handleTabChange}
						aria-label="Opções de contato"
					>
						<Tab label="Reclamações" value={0} />
						<Tab label="Sugestões" value={1} />
						<Tab label="Denúncias" value={2} />
						<Tab label="Trabalhe conosco" value={3} />
						<Tab label="Seja nosso fornecedor" value={4} />
					</Tabs>

					<div className="send-button" onClick={handleSubmit}>
						<img src={sendbutton} alt="Enviar"/>
					</div>
				</div>
			</form>
		</Container>
	);
};

export default Mailer;
