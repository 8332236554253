import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import UserPageLayout from "./styles.js";

const UserPages = () => {
	return (
		<UserPageLayout>
			<div className="page-content-wrapper">
				<Outlet />
			</div>
			<Footer />
		</UserPageLayout>
	);
};

export default UserPages;
