import React, { useEffect } from "react";
import {
    ButtonContainer,
    Container,
    StyledButton,
    Field,
} from "../../../styles/Adm/ShowPages/styles";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import toast from "react-hot-toast";

const ShowProvider = () => {
    let { id } = useParams();

    const [provider, setProvider] = useState({});
    const fetchProvider = async () => {
        await api
            .get(`/providers/show/${id}`)

            .then((response) => {
                setProvider(response.data);
            })

            .catch((response) => {
                toast.error("Fornecedor não encontrado.");
            });
    };

    useEffect(() => {
        fetchProvider();
    }, [id]); // eslint-disable-line

    return (
        <Container>
            <Field>
                <img
                    src={apiPhoto.defaults.baseURL + provider.logo_url}
                    className="main-photo"
                    alt="Logo do provedor"
                />
            </Field>
            <Field>
                <h1> {provider.name} </h1>
            </Field>
            <Field>
                <h2> Website </h2>
                {provider.website ? (
                    <p> {provider.website} </p>
                ) : (
                    <p> Website não cadastrado </p>
                )}
            </Field>
            <ButtonContainer className="button-container">
                <Link to={"/admin/fornecedores/editar/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to={"/admin/fornecedores/editar_imagens/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar Imagem
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to="./../">
                    <StyledButton variant="contained" fullWidth>
                        Concluir
                    </StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default ShowProvider;
