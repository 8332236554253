import Checkbox from "../../../components/CheckBox";
import TextField from "../../../components/Input";
import Button from "../../../components/Button";
import styled from "styled-components";

export const Container = styled.div`
	header,
	form,
	.button-container {
		max-width: 70%;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const Header = styled.header`
	h1 {
		font-size: var(--create-h1-font-size);
		padding-top: 1rem;
	}

	h2 {
		font-size: var(--create-h2-font-size);
		font-weight: normal;
		color: #575f6e;
		margin-top: 1rem;
	}
`;

export const Form = styled.form`
	margin-top: 2.5rem;
	min-height: 50%;
	border: 1px solid #e2e4e5;
	border-radius: 8px;
	display: flex;
	flex-flow: column wrap;
	flex: 2;
`;

export const InputContainer = styled.div`
	padding: 1.5rem;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;

	label {
		font-weight: bold;
		font-size: var(--create-label-font-size);
	}

	.label-description {
		margin-top: 0.5rem;
		font-size: var(--create-label-description-font-size);
		color: #575f6e;
		font-weight: 300;
	}

	h1 {
		font-size: var(--create-h1-font-size);
		padding-top: 1rem;
	}

	h2 {
		font-size: var(--create-h2-font-size);
		font-weight: normal;
		color: #575f6e;
		margin-top: 1rem;
	}

	p {
		font-size: var(--p-font-size);
	}
`;

export const Input = styled(TextField)`
	&& {
		margin-top: 3rem;
		input,
		textarea {
			font-size: var(--create-input-font-size);
		}
	}
`;

export const ButtonContainer = styled.div`
	margin-top: 2rem;
	a {
		text-decoration: none;
		display: contents;
	}
	button.smaller-button {
		font-size: var(--create-add-button-font-size);
	}
`;

export const StyledButton = styled(Button)`
	&& {
		background-color: var(--clr-yellow);
		font-size: var(--create-send-button-font-size);
		margin-bottom: 1rem;
	}
`;

export const StyledCheckbox = styled(Checkbox)`
	&& {
		align-self: flex-start;
		margin-left: -0.7rem;
	}
`;

export const RemoveImageButton = styled(Button)`
	&& {
		max-width: 200px;
		margin: 1rem 0 0 0;
		padding: 0.2em;
		font-size: var(--create-label-description-font-size);
	}
`;

export const CustomDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	img {
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
`;

export const CustomForm = styled.form`
	margin-bottom: 2rem;
	margin-top: 2rem;

	h3 {
		margin-bottom: 2rem;
	}

	input {
		display: flex;
		align-items: center;
		text-align: center;
		border: thin solid var(--clr-secondary);
		width: 20rem;
		max-width: 100%;
		aspect-ratio: 2 / 1;
		margin-top: 1rem;
	}

	input[type="file"]::file-selector-button {
		margin-inline: auto;
		font-size: 1.2rem;
		width: 200px;
		max-width: 100%;
		height: 2.2rem;
		border-radius: 0 0 10px 10px;
		background-color: var(--clr-primary);
		border: none;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
export const SubmitImageButton = styled(RemoveImageButton)`
	&& {
		margin: 1rem 0;
	}
`;

export const CustomHeader = styled.h1`
	font-size: var(--create-h1-font-size);
`;
