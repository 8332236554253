import styled from "styled-components";
import Button from "../../../components/Button";

export const Section = styled.section`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	--button-width: min(25rem, 80%);
	padding-bottom: 5rem;

	.title-container {
		display: flex;
		width: 80%;
		align-items: left;
		margin-bottom: 2rem;
	}

	h1 {
		margin: 0 2rem;
		font-size: 2.5rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	a {
		text-decoration: none;
		display: contents;
	}

	.head-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		width: 90%;
		height: auto;
		margin: 0.5rem;
	}

	.table-titles {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 98%;
		height: 100%;
		padding: 1rem;
	}

	.containers {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* .add-member {
        width: 55%;
        height: 3rem;
        border-radius: 3px;
        background-color: #F5B40A;
        border: none;
        color: white;
        font-size: 1.2rem;
        margin: 2rem 0 0 -3rem;
        cursor: pointer;
    } */

	@media screen and (max-width: 400px) {
		.title-container h1 {
			font-size: 2rem;
			margin: 0;
		}

		.table-titles h2 {
			font-size: 1rem;
		}

		.head-container {
			margin: 0rem;
		}
	}
	@media screen and (max-width: 800px) {
		.null-on-mobile {
			display: none;
		}
	}
`;
export const Container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 90%;
	height: auto;
	margin: 0.5rem;
	margin-bottom: 3rem;
	border: 1.5px solid #c4c4c4;
	border-radius: 8px;
`;

export const ElementContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 98%;
	height: 100%;
	padding: 1rem 0rem 1rem 0rem;
	border-bottom: 1.5px solid #c4c4c4;

	a {
		text-decoration: none;
		color: black;
		padding: 5% 0;
	}
	.containers {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 0.4rem 0 0.4rem;
		font-size: 1.2rem;
		word-break: break-all;
	}

	@media screen and (max-width: 900px) {
		a {
			padding: 1rem 0;
		}
		.containers {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		@media screen and (max-width: 400px) {
			.containers {
				font-size: 1rem;
			}
		}
	}
	@media screen and (max-width: 800px) {
		.null-on-mobile {
			display: none;
			font-size: 0;
		}
	}
`;

export const StyledButton = styled(Button)`
	&& {
		width: 7rem;
		min-width: max-content;
		height: 2.2rem;
		border-radius: 10px;
		background-color: #f5b40a;
		border: none;
		color: white;
		margin: 0 0.5rem 0 0.5rem;
		display: flex;
		justify-content: space-around;
		align-items: center;
		vertical-align: center;
		word-break: normal;

		@media screen and (max-width: 900px) {
			width: 5.5rem;
			height: 1.4rem;
			font-size: 0.6rem;
			margin: 0.5rem 0 0.5rem 0;
		}

		@media screen and (max-width: 400px) {
			width: 5rem;
			height: 1.1rem;
			font-size: 0.7rem;
			margin: 0.5rem 0 0.5rem 0;
		}

		.add-member {
			width: 55%;
			height: 3rem;
			border-radius: 3px;
			background-color: #f5b40a;
			border: none;
			color: white;
			font-size: 1.2rem;
			margin: 2rem 0 0 -3rem;
			cursor: pointer;
		}
	}
	@media (pointer: coarse) {
		min-height: 30px;
	}
`;
