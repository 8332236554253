import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Creatable from "../../../components/Creatable";
import { api } from "../../../services/api";
import {
    Container,
    Header,
    Form,
    InputContainer,
    Input,
    ButtonContainer,
    StyledButton,
    StyledCheckbox,
} from "../../../styles/Adm/Create&EditPages/styles";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import toast from "react-hot-toast";
import { getOptionsFromArrOfObjs } from "../../../services/utils/getOptions";

let retryUpdate = false; // true se tentou sair da página e salvar valores invalidos
let updated = false; // true se alterou localmente algum atributo da model
let startingEquipment = {};

const cleanup = () => {
    retryUpdate = false;
    updated = false;
    startingEquipment = {};
};

const EditEquipmentPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [equipment, setEquipment] = useState(startingEquipment);

    const equipmentRef = useRef();
    equipmentRef.current = { equipment, updated, retryUpdate };

    const [nameOptions, setNameOptions] = useState([]);
    const [placeOptions, setPlaceOptions] = useState([]);

    const orderValueFunction = (a, b) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }
        // a must be equal to b
        return 0;
    };

    useEffect(() => {
        async function fetchEquipment() {
            await api
                .get(`/equipments/show/${id}`)
                .then((response) => {
                    response.data.price = (response.data.price / 100)
                        .toString()
                        .replace(".", ",");
                    setEquipment(response.data);
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        toast.error("Equipamento não encontrado");
                    } else {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    }
                });
        }
        if (!equipmentRef.current.retryUpdate) {
            fetchEquipment();
        }

        async function fetchEquipmentsNameAndPlace() {
            await api
                .get(`/equipments/`)
                .then(({ data }) => {
                    let foundNameOptions = getOptionsFromArrOfObjs(
                        data,
                        "name"
                    );
                    let foundPlaceOptions = getOptionsFromArrOfObjs(
                        data,
                        "place"
                    );

                    setNameOptions(foundNameOptions.sort(orderValueFunction));
                    setPlaceOptions(foundPlaceOptions.sort(orderValueFunction));
                })
                .catch((e) => {
                    const data = e.response.data;
                    toast.error(
                        data?.error
                            ? data.error
                            : data?.message
                            ? data.message
                            : e.message
                    );
                });
        }
        fetchEquipmentsNameAndPlace();
    }, [id]);

    const handlePrice = (value) => {
        value = value.replace(",", ".");

        if (isNaN(value)) {
            return NaN;
        }
        if (!Number.isInteger(Math.round(value * 100))) {
            return NaN;
        }
        return Number.parseInt(Math.round(value * 100));
    };

    const validateEquipment = ({
        brand,
        name,
        version,
        series,
        year,
        hour_meter,
        price,
    }) => {
        if (!name || name === "") {
            toast.error("Coloque um nome");
            return false;
        }
        if (!brand || brand === "") {
            toast.error("Coloque uma marca");
            return false;
        }
        if (!version || version === "") {
            toast.error("Coloque um modelo");
            return false;
        }
        if (!series || series === "") {
            toast.error("Coloque uma série");
            return false;
        }
        if (!year || year === "") {
            toast.error("Coloque um ano");
            return false;
        }
        if (
            hour_meter != null &&
            hour_meter !== "" &&
            (isNaN(hour_meter) ||
                !Number.isInteger(Number.parseInt(hour_meter)) ||
                hour_meter < 0)
        ) {
            console.log(equipment);
            toast.error("Coloque um horímetro válido ou nenhum horímetro");
            return false;
        }
        if (
            price != null &&
            (isNaN(handlePrice(price)) ||
                !Number.isInteger(handlePrice(price)) ||
                handlePrice(price) < 0)
        ) {
            toast.error("Coloque um preço válido ou não coloque preço");
            return false;
        }
        return true;
    };

    const updateEquipment = (
        {
            brand,
            name,
            version,
            series,
            year,
            description,
            place,
            hour_meter,
            contact_info,
            price,
            for_sale,
        },
        redirect = true
    ) => {
        cleanup();

        price = handlePrice(price);
        api.patch(`/equipments/update/${id}`, {
            brand,
            name,
            version,
            series,
            year,
            description,
            place,
            hour_meter,
            for_sale,
            price,
            contact_info,
        })
            .then((response) => {
                toast.success("Equipamento editado com Sucesso");
                if (redirect) {
                    navigate(`/admin/equipamentos/${response.data.id}`);
                }
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateEquipment(equipment)) {
            updateEquipment(equipment);
        }
    };

    useEffect(() => {
        const handleLinkClick = (e) => {
            const target = e.target || e.srcElement;
            if (!equipmentRef.current.updated) {
                return;
            }

            if (target.tagName === "A" || target.parentNode.tagName === "A") {
                if (window.confirm("Salvar alterações?")) {
                    e.preventDefault();

                    const current = { ...equipmentRef.current };

                    if (validateEquipment(current.equipment)) {
                        updateEquipment(current.equipment, false);
                    } else {
                        navigate(`/admin/equipamentos/editar/${id}`);
                        retryUpdate = true;
                        startingEquipment = { ...current.equipment };
                    }
                } else {
                    cleanup();
                }
            }
        };

        setTimeout(() => {
            document.addEventListener("click", handleLinkClick);
        }, 100);

        return () => {
            setTimeout(() => {
                document.removeEventListener("click", handleLinkClick);
            }, 100);
        };
    });

    return (
        <Container>
            <Header>
                <h1>Editar Equipamento</h1>
                <h2>Modifique os campos para editar este equipamento</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do Equipamento</label>
                    <Creatable
                        value={{
                            value: equipment.name,
                            label: equipment.name,
                        }}
                        options={nameOptions}
                        id="name"
                        onChange={(opt) => {
                            setEquipment({ ...equipment, name: opt.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="brand">Marca</label>
                    <p className="label-description">
                        Preencha o campo com a marca do equipamento
                    </p>
                    <Input
                        placeholder="Marca*"
                        value={equipment.brand}
                        id="brand"
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                brand: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="year">Ano</label>
                    <p className="label-description">
                        Preencha o campo com o ano de fabricação do equipamento
                    </p>
                    <Input
                        placeholder="Ano*"
                        value={equipment.year}
                        id="year"
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                year: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="version">Modelo</label>
                    <p className="label-description">
                        Preencha o campo com a modelo do equipamento
                    </p>
                    <Input
                        placeholder="Modelo*"
                        id="version"
                        value={equipment.version}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                version: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="series">Série</label>
                    <p className="label-description">
                        Preencha o campo com a série do equipamento
                    </p>
                    <Input
                        placeholder="Série*"
                        id="series"
                        value={equipment.series}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                series: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="for_sale">À venda</label>
                    <p className="label-description">
                        Marque se deseja que o equipamento esteja a venda
                    </p>
                    <StyledCheckbox
                        id="for_sale"
                        size="large"
                        checked={equipment.for_sale ? true : false}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                for_sale: e.target.checked,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="local">Local</label>
                    <p className="label-description">
                        Onde se encontra o equipamento
                    </p>
                    <Creatable
                        disabled={!equipment.for_sale}
                        value={{
                            value: equipment.place,
                            label: equipment.place,
                        }}
                        options={placeOptions}
                        id="local"
                        onChange={(opt) => {
                            setEquipment({ ...equipment, place: opt.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="description">Descrição</label>
                    <p className="label-description">
                        Dê uma descrição do equipamento.
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Descrição*"
                        multiline
                        minRows={4}
                        value={equipment.description}
                        id="description"
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                description: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="horimeter">Horímetro</label>
                    <p className="label-description">
                        Preencha o campo com o horímetro do equipamento
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Horímetro*"
                        id="horimeter"
                        value={equipment.hour_meter}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                hour_meter: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="price">Preço</label>
                    <p className="label-description">
                        Preencha o campo com o preço, caso seja definido. Deixe
                        em branco se for a combinar.
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Preço*"
                        id="price"
                        value={equipment.price}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                price: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="contact_info">Contato</label>
                    <p className="label-description">
                        Escreva o contato do equipamento e, se necessário, o
                        meio de contato
                    </p>
                    <Input
                        disabled={!equipment.for_sale}
                        placeholder="Preço*"
                        id="contact_info"
                        value={equipment.contact_info}
                        onChange={(e) => {
                            setEquipment({
                                ...equipment,
                                contact_info: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <Link to={`/admin/equipamentos/editar_imagens/${id}`}>
                    <StyledButton
                        variant="contained"
                        endIcon={<MdEdit size={14} />}
                    >
                        Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <StyledButton
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    Concluir
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default EditEquipmentPage;