import React from "react";
import { Section } from "../../../../styles/ServicesPages/styles.js";
import image1 from "../../../../assets/images/ServicesPage/Estradas/DSC09576.jpg";
import image2 from "../../../../assets/images/ServicesPage/Estradas/DSC09585.jpg";

const InfraestructurePage = () => {
    return (
        <Section>
            <div className="item-container">
                <h1> INFRAESTRUTURA </h1>
            </div>

            <div className="item-container">
                <p>
                    A Mark’s Engenharia presta serviços especializados
                    relacionados a Infraestrutura como saneamento, transporte
                    (ferrovias, rodovia, entre outros), energia e
                    telecomunicações. Sabemos da enorme importância destes
                    serviços para o desenvolvimento socioeconómico, atraindo
                    investimento e gerando emprego. Por isso, utilizamos
                    equipamentos de última geração, com uma equipe técnica
                    especializada para atender nossos clientes.
                </p>
            </div>

            <div className="img-container">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
            </div>
        </Section>
    );
};

export default InfraestructurePage;
