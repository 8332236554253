import styled from "styled-components";
import { Button } from "@mui/material";
import mainImage from "../../../assets/images/Homepage/imagem principal.png";

export const Section1 = styled.section`
	width: 100%;
	min-height: 100vh;
	background: url("${mainImage}") no-repeat;
	background-size: cover;
	background-position: center;
`;

export const Section2 = styled.section`
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	box-sizing: border-box;

	a {
		text-decoration: none;
		width: max-content;
		max-width: 100%;
	}
`;

export const StyledButton = styled(Button)`
	&& {
		height: 4.8rem;
		max-width: 100%;
		width: max-content;
		color: white;
		border: 3px solid currentColor;
		border-radius: none;
		display: flex;
		justify-content: space-around;
		align-items: center;
		font-size: var(--font-size-text);
		font-weight: 500;
		font-family: var(--font-family-secondary);

		&:hover {
			border: 1.5px solid currentColor;
		}
	}
`;

export const Section3 = styled.section`
	width: 100%;
	min-height: 80vh;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	.links-container {
		align-self: center;
		flex-wrap: wrap;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;
		width: 81%;
		gap: 1rem;

		a {
			text-decoration: none;
		}

		.link-box {
			border: 2px solid var(--clr-primary);
			height: clamp(6rem, 4rem + 10vw, 14rem);
			width: clamp(6rem, 4rem + 10vw, 14rem);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transition: ease 0.3s;

			img {
				width: 55%;
				height: 55%;
			}

			.caderno {
				margin-left: 1rem;
			}

			h3 {
				font-family: var(--font-family-secondary);
				font-weight: 600;
				font-size: clamp(0.8rem, 0.7rem + 0.9vw, 1.8rem);
				margin-bottom: -0.7em;
				color: black;
			}

			&:hover {
				border-width: 1.5px;
				transform: scale(1.05);
			}
		}
	}
`;

export const Section4 = styled.section`
	width: 100%;
	min-height: 100vh;
	background-color: var(--clr-primary);
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	justify-content: space-evenly;

	.mailer-container {
		height: max-content;
	}

	.button-container {
		width: 100%;
		display: flex;
		justify-content: right;
		align-items: center;
	}
`;

export const Section5 = styled.section`
	min-height: 110vh;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	box-sizing: border-box;
`;
