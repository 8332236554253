import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../services/api";
import { Container } from "./styles";
import { Carousel } from "../../../components/Carousel";
import toast from "react-hot-toast";

const ShowProject = () => {
    const [project, setProject] = useState([]);
    const [photos, setPhotos] = useState([]); // Array of Objects with url of the image, isSelected if it is selected and id for map keys and correlation with the value variable

    const formatDate = (date) =>
        date?.slice(0, 10).split("-").reverse().join("/");

    const id = useParams().id;
    useEffect(() => {
        api.get(`/projects/show/${id}`)
            .then((response) => {
                setProject(response.data);
                let tempPhotos = [];
                if (response.data.photos_url && response.data.photos_url[0]) {
                    tempPhotos = response.data.photos_url.map((url, index) => {
                        return {
                            isImage: true,
                            url: url,
                            isSelected: false,
                            id: index,
                        };
                    });
                }
                const numImages = tempPhotos.length;
                tempPhotos = [
                    ...tempPhotos,
                    ...response.data.youtube_links.map((link, index) => {
                        return {
                            isImage: false,
                            url: link,
                            isSelected: false,
                            id: numImages + index,
                        };
                    }),
                ];
                tempPhotos[0].isSelected = true; // First photo or video is selected by default
                if (tempPhotos && tempPhotos[0]) {
                    setPhotos(tempPhotos);
                }
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    return (
        <Container className="inverted-colors">
            <h1>{project?.name}</h1>

            {
                // Ainda falta componentizar:
                photos && photos[0] && (
                    <Carousel photos={photos} carouselFor={project.name} />
                )
            }
            <div className="info ">
                <p>Descrição do projeto: {project?.description}</p>
                <p>Local: {project?.place}</p>
                <p>
                    Data prevista:{" "}
                    {formatDate(project?.start_date) +
                        " - " +
                        formatDate(project?.end_date)}
                </p>
            </div>
        </Container>
    );
};

export default ShowProject;
