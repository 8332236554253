import styled from "styled-components";
import reactSelect from "react-select";

export const Select = styled(reactSelect)`
    font-size: var(--font-size-text);
    width: 30rem;
    max-width: 100%;
    z-index: 99;
    margin-inline: auto;
    position: relative;

    .react-select__control {
        text-align: center;
        height: 5rem;
        border-radius: 100vmax;
        box-shadow: 0 0 0 1px var(--clr-primary);
        border-color: var(--clr-primary);
        flex-direction: row-reverse;

        margin-bottom: -2px;
        &:hover {
            border-color: var(--clr-secondary);
        }
    }
    .react-select__control--menu-is-open {
        border-radius: 50px 50px 0 0;
        border-color: var(--clr-primary);
    }

    .react-select__indicators {
        position: absolute;
        left: 0;
        bottom: 10%;
        top: 10%;
        flex-direction: row-reverse;
        & > * {
            color: var(--clr-primary);
            &:hover {
                color: var(--clr-primary);
            }
        }
        & svg {
            margin-left: 1rem;
            width: 2rem;
            height: 2rem;
        }
    }

    .react-select__indicator-separator {
        background-color: initial;
    }

    .react-select__placeholder,
    .react-select__value-container {
        color: var(--clr-primary);
        font-family: var(--font-family-tertiary);
    }

    .react-select__placeholder {
        font-weight: 700;
        text-indent: -2rem;
    }

    .react-select__input-container {
        input {
            pointer-events: none;
            caret-color: transparent;
        }
    }

    .react-select__menu {
        z-index: -1;
        margin: 0;
        border-radius: 0 0 50px 50px;
        overflow: hidden;
        background-color: var(--clr-primary);
    }

    .react-select__option {
        height: 4rem;
        text-align: center;
        color: var(--clr-primary);
        background-color: white;
        margin-top: 3px;
    }

    .react-select__option--is-focused {
        &:active {
            background-color: white;
        }
        &:hover {
            --lightness: 240;
            background-color: rgb(
                var(--lightness),
                var(--lightness),
                var(--lightness)
            );
        }
    }
`;
