import React from "react";
import { StyledInput } from "./style.js";

const Input = (props) => {

    return (
        <StyledInput variant="standard" {...props}/>

    )
}

export default Input