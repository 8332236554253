import Creatable from "react-select/creatable";
import styled from "styled-components";

export const Select = styled(Creatable)`
	&& {
		margin-top: 3rem;
		font-size: var(--create-input-font-size);
		.css-1pahdxg-control {
			border-color: var(--clr-primary);
		}
		.css-1pahdxg-control {
			box-shadow: 0 0 0 1px var(--clr-primary);
		}
		.css-26l3qy-menu {
			background-color: #ffb941d9;
		}
		.css-9gakcf-option {
			background-color: var(--clr-primary);
			&:hover {
			}
		}
		.css-1n7v3ny-option {
			&:active {
				background-color: #ffb941d9;
			}
			&:hover {
				background-color: #fff;
			}
		}
		${({ disabled }) => disabled && "opacity: 0.7; pointer-events: none;"}
	}
`;
