import styled from "styled-components";

export const CardWrapper = styled.li`
	display: flex;
	padding: 1.5rem;
	flex-wrap: wrap;
	justify-content: space-around;
	background-color: white;
	align-items: center;
	width: 85%;
	margin-block: 1.5rem;
	min-height: 428px;

	img {
		cursor: pointer;
		object-fit: cover;
		padding: 2rem;
		flex: 2;
		min-width: 300px;
		max-width: 100%;
		max-height: 24rem;
	}

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
	}
	@media only screen and (max-width: 430px) {
		padding: 0;
		div,
		img {
			min-width: initial;
			padding: 5%;
		}
	}
	@media only screen and (max-width: 300px) {
		img {
			padding: 0;
		}
	}
`;

export const CardContentWrapper = styled.div`
	flex: 3;
	padding: 2rem;
	font-family: var(--font-family-tertiary);
	font-size: var(--font-size-text);
	align-self: flex-start;
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;

		p {
			color: black !important;
			text-align: initial !important;
		}
	}

	.break-words {
		// melhor responsividade
		overflow-wrap: break-word;
		word-break: break-all;
		hyphens: manual;
	}

	h4 {
		font-weight: 700;
		font-size: calc(var(--font-size-text) + 0.2rem);
	}

	b {
		font-size: calc(var(--font-size-text) + 0.1rem);
		font-weight: 500;
	}

	@media only screen and (max-width: 300px) {
		padding-inline: 0;
		.grid {
			grid-gap: 0;
			display: flex;
			flex-direction: column;
		}
	}
`;
