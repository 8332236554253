import { useEffect } from "react";
import { useLocation } from "react-router";

// if location changes (Link clicked, e.g.) page is scrolled to top

let alreadyOnPage = false;

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        if (!alreadyOnPage) {
            alreadyOnPage = true;
            return;
        }
        document
            .getElementsByTagName("body")[0]
            .scrollTo({ top: 100, behavior: "smooth" });
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [location.key]);

    return children;
};

const usePathChange = (onPathChange) => {
    const location = useLocation();

    useEffect(() => {
        onPathChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.key]);
};

export default ScrollToTop;
export { usePathChange };
