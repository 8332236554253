import React from "react";
import { useState } from "react";
import { Container } from "./styles";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/MarksLogo.png";
import { FaBars } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { usePathChange } from "../../routes/utils";

const NavBar = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState();

    const toggleSidebar = () => {
        setIsSidebarVisible((s) => !s);
    };

    usePathChange(() => {
        setIsSidebarVisible(false);
    });

    const navLinkStyle = ({ isActive }) => {
        if (isActive) {
            return { opacity: 0.65, textShadow: "0px 1px 1px grey" };
        }
    };

    return (
        <Container isSidebarVisible={isSidebarVisible}>
            <div aria-hidden className="logo">
                <NavLink to="/" aria-label="Página inicial">
                    <img src={logo} alt="logo da página" />
                </NavLink>
            </div>
            <span className="navbar-toggle" onClick={toggleSidebar}>
                <FaBars />
            </span>
            <ul className="middle">
                <li className="navbar-toggle" onClick={toggleSidebar}>
                    <FiArrowLeft size={"2.5rem"} />
                </li>

                <li className="item-container">
                    <NavLink style={navLinkStyle} to="/sobreNos">
                        SOBRE NÓS
                    </NavLink>
                </li>
                <li className="item-container">
                    <NavLink style={navLinkStyle} to="/projetos">
                        PROJETOS
                    </NavLink>
                </li>
                <li className="item-container">
                    <NavLink style={navLinkStyle} to="/fornecedores">
                        FORNECEDORES
                    </NavLink>
                </li>
                <li className="item-container">
                    <NavLink style={navLinkStyle} to="/equipamentos">
                        EQUIPAMENTOS
                    </NavLink>
                </li>
                <li className="item-container">
                    <NavLink style={navLinkStyle} to="/servicos">
                        SERVIÇOS
                    </NavLink>
                </li>
                <li className="last-item-container">
                    <NavLink style={navLinkStyle} to="/contato">
                        CONTATO
                    </NavLink>
                </li>
            </ul>
        </Container>
    );
};

export default NavBar;
