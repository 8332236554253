import styled from "styled-components";

export const PageWrapper = styled.section`
	display: flex;
	flex-direction: column;
	min-height: 70vh;

	p {
		font-size: var(--font-size-text);
		padding-block: 1rem;
	}

	a {
		text-decoration: underline var(--clr-secondary);
		color: var(--clr-primary);
		font-weight: 600;
		&:hover {
			text-decoration-thickness: 3px;
		}
	}

	.provider-link {
		text-decoration: none;
		display: contents;
	}

	.center {
		text-align: center !important;
		margin-inline: auto !important;
	}

	@media (pointer: coarse) {
		// maior area para clique em mobile
		a {
			padding: 1.5rem;
		}
	}
`;

export const ProvidersWrapper = styled.div`
	display: ${({ style }) => style?.display || "flex"};
	flex-wrap: wrap;
	gap: 4.5rem;
	padding-block: 4rem;
	justify-content: center;

	img {
		width: 13rem;
		height: 12rem;
		object-fit: contain;
		object-position: center center;
	}
`;
