import styled from "styled-components";

const AdminPageLayout = styled.div`
	display: flex;
	flex-direction: row;
	& > div {
		height: auto;
	}
	.page-content-wrapper {
		padding: 6rem 0 0 1rem;
		width: 100%;
	}
`;
export default AdminPageLayout;
