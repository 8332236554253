import React from "react";
import { Section } from "../../../../styles/ServicesPages/styles.js";
import image1 from "../../../../assets/images/ServicesPage/Compactacao/Compactacao_de_aterro_3.jpg";
import image2 from "../../../../assets/images/ServicesPage/Compactacao/Tratamento de camada 1.jpg";

const Landscaping = () => {
    return (
        <Section>
            <div className="item-container">
                <h1> TERRAPLENAGEM </h1>
            </div>

            <div className="item-container">
                <p>
                    Além de profissionais experientes, outro grande diferencial
                    da Mark’s Engenharia é a ampla lista de máquinas e
                    equipamentos modernos, utilizados na execução da
                    Terraplenagem, investindo fortemente em novas tecnologia,
                    cada um criteriosamente selecionado para atender as
                    necessidades de nossos clientes. São diversas obras de
                    terraplenagem, e milhões de m³ em movimentação de terra
                    entregues em todo o Brasil, sempre alcançando a qualidade
                    exigida e a satisfação pelos nossos clientes.
                </p>
            </div>

            <div className="img-container">
                <img src={image1} alt="" />
                <img src={image2} alt="" className="bigger" />
            </div>
        </Section>
    );
};

export default Landscaping;
