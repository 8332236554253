import { BottomNavigationAction } from "@mui/material";
import React from "react";
import { StyledBottomNavigation } from "./styles";
import { BsCircle } from "react-icons/bs";
import { BsCircleFill } from "react-icons/bs";

const BottomNavigationForImgs = ({
	selectedImgs,
	selectedIndex,
	color,
	...props
}) => {
	return (
		<StyledBottomNavigation {...props}>
			{selectedImgs?.map((img, i) => {
				return (
					<BottomNavigationAction
						key={"BottomNavigation" + img.url + i}
						icon={
							i === selectedIndex ? (
								<BsCircleFill color={color || "#fff"} />
							) : (
								<BsCircle />
							)
						}
					/>
				);
			})}
		</StyledBottomNavigation>
	);
};

export default BottomNavigationForImgs;
