import styled from "styled-components";

export const Container = styled.section`
	width: 80%;
	margin: 0 auto;
	padding: 1rem;
	padding-bottom: 5rem;
	min-height: 70vh;

	.info {
		margin-top: 3rem;
		font-family: var(--font-family-tertiary);
		font-size: var(--index-h2-font-size);
		margin-bottom: 5rem;
	}

	@media only screen and (max-width: 375px) {
		width: 100%;
		.title {
			width: fit-content;
		}
	}
`;
