import React, { useEffect, useState } from "react";
import ProjectCard from "../../../components/ProjectCard";
import { Container } from "./styles";
import { api } from "../../../services/api";
import toast from "react-hot-toast";

const IndexProjects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        api.get("/projects/")
            .then((response) => {
                setProjects(response.data);
            })
            .catch((e) => {
                const data = e?.response?.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, []);

    return (
        <Container>
            <h1 className="title">PROJETOS</h1>
            <p className="info">
                A Marks Engenharia é comprometida com a melhoria contínua de
                seus processos e direciona sua atenção para o atendimento das
                necessidades e expectativas de cada cliente, construindo uma
                história formada por relacionamentos tão duradouros e confiáveis
                quanto suas obras.
            </p>
            {projects.map((project) => (
                <ProjectCard key={project.id} project={project} />
            ))}
        </Container>
    );
};

export default IndexProjects;
