import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import {
    Container,
    Header,
    Form,
    InputContainer,
    Input,
    ButtonContainer,
    StyledButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

let retryUpdate = false;
let updated = false;
let startingProvider = {};

const cleanup = () => {
    retryUpdate = false;
    updated = false;
    startingProvider = {};
};

const EditProviderPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [provider, setProvider] = useState(startingProvider);
    const providerRef = useRef();
    providerRef.current = { provider, updated, retryUpdate };

    useEffect(() => {
        async function fetchProvider() {
            await api
                .get(`/providers/show/${id}`)
                .then((response) => {
                    setProvider(response.data);
                })
                .catch((e) => {
                    if (e.response.status === 404) {
                        toast.error("Fornecedor não encontrado");
                    } else {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    }
                });
        }

        if (!retryUpdate) {
            fetchProvider();
        }
    }, [id]);

    const validateProvider = ({ name }) => {
        if (!name || name === "") {
            toast.error("Coloque um nome");
            return false;
        }
        return true;
    };

    const updateProvider = ({ name, website }, redirect = true) => {
        cleanup();

        api.patch(`/providers/update/${id}`, {
            name,
            website,
        })
            .then((response) => {
                toast.success("Fornecedor Editado com Sucesso");
                if (redirect) {
                    navigate(`/admin/fornecedores/${response.data.id}`);
                }
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateProvider(provider)) {
            updateProvider(provider);
        }
    };

    useEffect(() => {
        const handleLinkClick = (e) => {
            const target = e.target || e.srcElement;
            e.preventDefault();

            if (!providerRef.current.updated) {
                return;
            }

            if (target.tagName === "A" || target.parentNode.tagName === "A") {
                if (window.confirm("Salvar alterações?")) {
                    const current = { ...providerRef.current };

                    if (validateProvider(current.provider)) {
                        updateProvider(current.provider, false);
                    } else {
                        navigate(`/admin/fornecedores/editar/${id}`);
                        retryUpdate = true;
                        startingProvider = { ...current.provider };
                    }
                } else {
                    cleanup();
                }
            }
        };

        setTimeout(() => {
            window.addEventListener("click", handleLinkClick);
        }, 100);

        return () => {
            setTimeout(() => {
                window.removeEventListener("click", handleLinkClick);
            }, 100);
        };
    });

    return (
        <Container>
            <Header>
                <h1>Editar Fornecedor</h1>
                <h2>Modifique os campos para editar este fornecedor</h2>
            </Header>

            <Form>
                <InputContainer>
                    <label htmlFor="name">Nome do fornecedor</label>
                    <Input
                        placeholder="Nome do fornecedor*"
                        value={provider.name}
                        id="name"
                        onChange={(e) => {
                            setProvider({ ...provider, name: e.target.value });
                            updated = true;
                        }}
                    />
                </InputContainer>

                <InputContainer>
                    <label htmlFor="website">Site</label>
                    <p className="label-description">
                        Escreva, ou cole, o link do site do fornecedor
                    </p>
                    <Input
                        placeholder="Site*"
                        value={provider.website}
                        id="website"
                        onChange={(e) => {
                            setProvider({
                                ...provider,
                                website: e.target.value,
                            });
                            updated = true;
                        }}
                    />
                </InputContainer>
            </Form>

            <ButtonContainer className="button-container">
                <Link to={`/admin/fornecedores/editar_imagens/${id}`}>
                    <StyledButton
                        variant="contained"
                        endIcon={<MdEdit size={14} />}
                    >
                        Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <StyledButton
                    onClick={handleSubmit}
                    variant="contained"
                    fullWidth
                >
                    Concluir
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default EditProviderPage;