import React from "react";
import { ClearButton, Input, RemoveIcon, Container } from "./styles";

const LinkInput = ({ value, onChangeFunction, onRemoveFunction }) => {
	return (
		<Container>
			{value === "" ? (
				<Input
					className="removable"
					placeholder="Link"
					onChange={onChangeFunction}
				/>
			) : (
				<Input
					className="removable"
					placeholder="Link"
					value={value}
					onChange={onChangeFunction}
				/>
			)}
			<ClearButton onClick={onRemoveFunction} aria-label="clear" size="small">
				<RemoveIcon size={"calc(1rem + 1vw)"} />
			</ClearButton>
		</Container>
	);
};

export default LinkInput;
