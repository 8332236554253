import React from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../../components/CustomSelect";
import { HeaderArea, PageWrapper } from "../styles";

const EquipmentsPage = () => {
    const navigate = useNavigate();

    const options = [
        { value: "Our", label: "Nossos Equipamentos" },
        { value: "For sale", label: "Venda de equipamentos" },
    ];

    const handleChange = (e) => {
        switch (e.value) {
            case "Our":
                navigate("/equipamentos/nossos");
                break;

            case "For sale":
                navigate("/equipamentos/venda");
                break;
            default:
                break;
        }
    };

    return (
        <PageWrapper className="inverted-colors">
            <HeaderArea>
                <h1>EQUIPAMENTOS</h1>
            </HeaderArea>
            <CustomSelect
                placeholder={"Selecione"}
                options={options}
                onChange={handleChange}
            />
        </PageWrapper>
    );
};

export default EquipmentsPage;
