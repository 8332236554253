import styled from "styled-components";

const MapContainer = styled.div`
	width: min(100%, 76rem);
	margin: 2rem auto 2rem auto;
	margin-inline: auto;
	text-align: center;

	address {
		font-family: var(--font-family-tertiary);
		font-style: normal;
		font-size: calc(var(--font-size-text) - 0.15rem);
		color: ${({ textColor }) => textColor || "var(--clr-primary)"};
		margin-top: 1rem;
		text-align: center;
	}

	iframe {
		width: 100%;
		aspect-ratio: 2 / 1;

		border-color: var(--clr-secondary);
		border-top-color: var(--clr-primary);
		border-left-color: var(--clr-secondary);
	}

	@media (max-width: 690px) {
		margin-top: 4rem;
		iframe {
			aspect-ratio: 1 / 1;
		}
	}
`;

export default MapContainer;
