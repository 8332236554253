import { Popover } from '@mui/material';
import React, { useState } from 'react'
import Button from '../Button';
import { Container } from './styles';

const PopupArea = ({children, buttonText, popoverProps, buttonProps, 
    ...rest}) => {

    const [anchorElement, setAnchorElement] = useState(null);

    const handleClickOpenFilter = (e) => {
        setAnchorElement(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const open = Boolean(anchorElement);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Container>
        <Button aria-describedby={id} onClick={handleClickOpenFilter} {...buttonProps}>
            FILTRO
        </Button>

        <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        {...popoverProps}
        >
            {children}
        </Popover>
    </Container>
  )
}

export default PopupArea