import { Button } from "@mui/material";
import styled, { css } from "styled-components";

export const StyledButton = styled(Button)`

	&& {
		background-color: var(--clr-yellow);
		color: white;
		text-transform: capitalize;
		font-size: var(--font-size-text);
		font-weight: 700;
		font-family: var(--font-family-tertiary);
		padding: 0.5em;
		margin-inline: 0.5rem;
		width: var(--button-width, 100%);


		&.small-font {
			font-size: clamp(0.9rem, 0.5rem + 1vw, 1.5rem);
		}

		&:hover {
			background-color: var(--clr-secondary);
		}
	}

	${(props) =>
		props.invertedColors &&
		css`
		&&{
			background-color: white;
			color: var(--clr-yellow);
			&:hover{ 
				background-color: var(--clr-secondary);
				color: white
			}
		}`
	}
`;
