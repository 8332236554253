import { createContext, useContext, useEffect, useState } from "react";
import { api } from "../services/api";
import Cookie from "js-cookie";
import toast from "react-hot-toast";

const AdminContext = createContext({});

const AdminProvider = ({ children }) => {
	const [admin, setAdmin] = useState(undefined);

	const handleCookies = async () => {
		const adminCookie = await Cookie.get("admin");
		if (adminCookie !== undefined) {
			const retrievedAdmin = JSON.parse(adminCookie);
			setAdmin(retrievedAdmin);

			api.defaults.headers.common["X-Admin-Token"] =
				retrievedAdmin.authentication_token;
			api.defaults.headers.common["X-Admin-Email"] = retrievedAdmin.email;
		}
		return adminCookie;
	};

	useEffect(() => {
		handleCookies();
	}, []);

	const login = async ({ email, password }) => {
		try {
			const adminResponse = await api.post(`/admins/login`, {
				email,
				password,
			});
			if (adminResponse.data) {
				setAdmin(adminResponse.data);

				Cookie.set("admin", JSON.stringify(adminResponse.data), { expires: 1 });

				api.defaults.headers.common[`X-Admin-Token`] =
					adminResponse.data.authentication_token;
				api.defaults.headers.common[`X-Admin-Email`] = adminResponse.data.email;
				return true;
			}
		} catch (e) {
			switch (e.response.status) {
				case 404:
					toast.error("Email ou senha incorreto.");
					break;
				case 401:
					toast.error("Email ou senha incorreto.");
					break;

				default:
					toast.error(e.message);
					break;
			}
		}
	};

	const logout = async () => {
		if (window.confirm("Deseja sair da sua conta?")) {
			try {
				api.post("admins/logout");
			} catch (e) {
				toast.error(e.message);
			} finally {
				Cookie.remove("admin");
			}
		}
	};

	return (
		<AdminContext.Provider value={{ admin, login, logout }}>
			{children}
		</AdminContext.Provider>
	);
};

const useAdminContext = () => {
	const context = useContext(AdminContext);
	return context;
};

export { AdminProvider, useAdminContext };
