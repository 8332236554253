import React, { useEffect } from "react";
import {
    ButtonContainer,
    Container,
    StyledButton,
    Field,
} from "../../../styles/Adm/ShowPages/styles";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import { Carousel } from "../../../components/Carousel";
import toast from "react-hot-toast";

const ShowProject = () => {
    let { id } = useParams();
    const [project, setProject] = useState({});
    const fetchProject = async () => {
        await api
            .get(`/projects/show/${id}`)
            .then((response) => {
                setProject(response.data);
            })

            .catch((response) => {
                toast.error("Projeto não encontrado.");
            });
    };

    useEffect(() => {
        fetchProject();
    }, [id]); // eslint-disable-line

    const formatCarouselContent = ({ photos_url, youtube_links }) => {
        return photos_url
            .map((url) => {
                return { url: url, isImage: true };
            })
            .concat(
                youtube_links?.map((url) => {
                    return { url: url, isImage: false };
                })
            );
    };

    return (
        <Container>
            <Field>
                <img
                    src={apiPhoto.defaults.baseURL + project.logo_url}
                    className="main-photo"
                    alt="Logo do projeto"
                />
            </Field>
            <Field>
                <h1> {project.name} </h1>
            </Field>
            <Field>
                <h2> Cliente </h2>
                <p> {project.client_name} </p>
            </Field>
            <Field>
                <h2> Descrição </h2>
                <p> {project.description} </p>
            </Field>
            <Field>
                <h2> Local </h2>
                <p> {project.place} </p>
            </Field>

            <Field>
                <h2> Data de início </h2>
                <p>
                    {project.start_date?.slice(8, 10)}/
                    {project.start_date?.slice(5, 7)}/
                    {project.start_date?.slice(0, 4)}
                </p>
            </Field>
            <Field>
                <h2> Data de término </h2>
                <p>
                    {project.end_date?.slice(8, 10)}/
                    {project.end_date?.slice(5, 7)}/
                    {project.end_date?.slice(0, 4)}
                </p>
            </Field>

            <Field
                className={
                    project?.photos_url && project.youtube_links
                        ? "carousel-container"
                        : ""
                }
            >
                <h2> Imagens e vídeos </h2>
                {project?.photos_url && project.youtube_links ? (
                    <Carousel
                        photos={formatCarouselContent(project)}
                        carouselFor={project.name}
                        color={"var(--clr-primary)"}
                    />
                ) : (
                    <p>Não há imagens</p>
                )}
            </Field>
            <ButtonContainer className="button-container">
                <Link to={"/admin/projetos/editar/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to={"/admin/projetos/editar_imagens/" + id}>
                    <StyledButton variant="contained" fullWidth>
                        Editar Imagens
                    </StyledButton>
                </Link>
            </ButtonContainer>

            <ButtonContainer className="button-container">
                <Link to="./../">
                    <StyledButton variant="contained" fullWidth>
                        Concluir
                    </StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default ShowProject;
