import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	height: fit-content;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	p {
		margin-block: 1rem 3rem;
	}

	.grid-container1 {
		display: grid;
		grid-template-columns: auto auto auto;
		grid-gap: 0;
		width: 100vw;
		height: 22rem;
		margin-inline: var(--reverse-section-inline-padding);
	}

	.grid-container2 {
		display: grid;
		grid-template-columns: auto auto auto auto;
		grid-gap: 0;
		width: 100vw;
	}

	.img-container {
		width: 100vw;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;

		margin: 0 var(--reverse-section-inline-padding);
		margin-bottom: var(--reverse-section-block-padding);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		img {
			flex: 1;
			max-width: 100%;
			min-width: min(35rem, 100%);
			margin: 0;
			object-fit: cover;
			object-position: left top;
			height: calc(20rem + 17vw);
		}

		.bigger {
			flex: 2;
		}
	}
`;
