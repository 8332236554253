import axios from "axios";

export const api = axios.create({
	baseURL: "https://api.marks.com.br/api/v1",
});

export const apiPhoto = axios.create({
	baseURL: "https://api.marks.com.br",
});

export const apiMailer = axios.create({
	baseURL: "https://api.marks.com.br",
});