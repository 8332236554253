import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { api, apiPhoto } from "../../../services/api";
import {
    ButtonContainer,
    CustomDiv,
    CustomForm,
    CustomHeader,
    InputContainer,
    StyledButton,
    SubmitImageButton,
} from "../../../styles/Adm/Create&EditPages/styles";
import { ProvidersWrapper } from "../../User/ProvidersPage/styles";

const AddProviderImagePage = () => {
    let { id } = useParams();
    const [actualLogo, setActualLogo] = useState([]);
    const [logo, setLogo] = useState();

    useEffect(() => {
        api.get(`/providers/show/${id}`)
            .then((response) => {
                setActualLogo(response.data.logo_url);
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    }, [id]);

    const editLogo = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("logo", logo);

        api.post(`providers/edit_logo/${id}`, formData)
            .then((response) => {
                api.get(`/providers/show/${id}`)
                    .then((response) => {
                        toast.success("logo adicionada");
                        setActualLogo(response.data.logo_url);
                    })
                    .catch((e) => {
                        const data = e.response.data;
                        toast.error(
                            data?.error
                                ? data.error
                                : data?.message
                                ? data.message
                                : e.message
                        );
                    });
            })
            .catch((e) => {
                const data = e.response.data;
                toast.error(
                    data?.error
                        ? data?.error
                        : data?.message
                        ? data.message
                        : e.message
                );
            });
    };

    return (
        <Container>
            <CustomHeader>Edite a imagem do Fornecedor:</CustomHeader>

            <CustomDiv>
                <ProvidersWrapper style={{ display: "contents" }}>
                    <img src={apiPhoto.defaults.baseURL + actualLogo} alt="" />
                </ProvidersWrapper>
            </CustomDiv>

            <CustomForm onSubmit={editLogo}>
                <InputContainer>
                    <label htmlFor="description">Editar imagem</label>
                    <p className="label-description">
                        Arraste uma imagem para a caixa pontilhada ou procure
                        uma em seu dispositivo
                    </p>
                    <input
                        accept="image/*"
                        placeholder="file"
                        type="file"
                        onChange={(event) => setLogo(event.target.files[0])}
                    />
                </InputContainer>

                <SubmitImageButton
                    disabled={!logo}
                    className="input-btn"
                    type="submit"
                >
                    Editar
                </SubmitImageButton>
            </CustomForm>

            <ButtonContainer className="button-container">
                <Link to={`/admin/fornecedores/${id}`}>
                    <StyledButton variant="contained">Concluir</StyledButton>
                </Link>
            </ButtonContainer>
        </Container>
    );
};

export default AddProviderImagePage;
